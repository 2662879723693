import PouchDB from 'pouchdb'
import PouchDBFind from 'pouchdb-find';


PouchDB.plugin(PouchDBFind)

const db = new PouchDB('_pouch_CONET_silent_pass')

async function savePurchase(userId: string, purchaseTime: number, ipAddress: string, entryNode: string, exitNode: string): Promise<void> {
    try {
        await db.post({
            _id: Date.now().toString(),
            userId: userId,
            purchaseTime: purchaseTime,
            ipAddress: ipAddress,
            entryNode: entryNode,
            exitNode: exitNode
        })
    } catch (err) {
        console.log(err)
    }
}

async function getLastPurchase(userId: string) {
    try {
        let lastPurchase: any = undefined

        await db.createIndex({
            index: { fields: ['purchaseTime', 'userId'] }
        })

        const result = await db.find({
            selector: { userId: { $eq: userId } },
        })

        if (result.docs.length > 0) {
            const sortedRes = result.docs.sort((a: any, b: any) => b.purchaseTime - a.purchaseTime)
            lastPurchase = sortedRes[0]
            return { purchaseTime: lastPurchase.purchaseTime, ipAddress: lastPurchase.ipAddress, entryNode: lastPurchase.entryNode, exitNode: lastPurchase.exitNode }
        } else {
            return null
        }

    } catch (error) {
        console.error("Failed to update ipAddress: ", error);
        throw error;
    }
}

async function updateIpAdress(userId: string, newIPAddress: string, newIngressNode: string, newEgressNode: string) {
    try {
        let lastPurchase: any = undefined

        await db.createIndex({
            index: { fields: ['purchaseTime', 'userId'] }
        })

        const result = await db.find({
            selector: { userId: { $eq: userId } },
        })

        if (result.docs.length > 0) {
            const sortedRes = result.docs.sort((a: any, b: any) => b.purchaseTime - a.purchaseTime)
            lastPurchase = sortedRes[0]
            await db.put({
                _id: lastPurchase._id,
                _rev: lastPurchase._rev,
                userId: lastPurchase.userId,
                purchaseTime: lastPurchase.purchaseTime,
                ipAddress: newIPAddress,
                entryNode: newIngressNode,
                exitNode: newEgressNode
            })
        } else {
            return null
        }

    } catch (error) {
        console.error("Failed to get last purchase: ", error);
        throw error;
    }
}

export { savePurchase, getLastPurchase, updateIpAdress }