import { useIntl } from "react-intl"
import { styled as materialStyled, Box, ClickAwayListener, InputBase, Paper, Button, Skeleton, CircularProgress } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Stack, SvgIcon } from '@mui/material'
import React, { useState, useEffect } from "react"
import CircleIcon from '@mui/icons-material/Circle'
import styled from 'styled-components'
import { ReactComponent as defaultProfilePicture } from './assets/default_profile.svg'
import { ReactComponent as CONETIcon } from '../../../../assets/logo/CoNET_logo.svg'
import { motion } from 'framer-motion'

import cloud from '../miner/assets/cloud.svg'

import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import InfoCard from "./pags/InfoCard"
import SettingsCard from "./pags/SettingsCard"
import TransactionCard from "./pags/TransactionCard"
import { IProfile } from "./interface/profile.interface"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import store, { useTypedSelector } from "../../../../store/store"
import { platform } from '../../../../API/platform';
import { ButtonLabel, ContainerButton } from "../dashboard/styles"
import { toast } from 'react-toastify'
import NewWalletModal from "./pags/NewWalletModal"
import { setActiveProfile } from "../../../../store/appState/appStateActions"

import { ReactComponent as CloseIcon } from '../../../../assets/icons/Close.svg'
import { ReactComponent as CloseIconBlack } from '../../../../assets/icons/Close_black.svg'
import { useIsMiningRunning } from "../miner/pags/useIsMiningRunning"
import { useUserProfile } from "../../../../API/useUserProfile"
import { useRefreshStore } from "../../../../API/useRefreshStore";
import { useRef } from "react"

const SearchInput = styled.input`
    border: 0px;
    width: 100%;

    &:focus {
        outline: none;
    }
`;

const InputStyle = materialStyled(Paper)(() => ({
    display: 'flex',
    textAlign: 'center',
    marginBottom: '16px',
    borderRadius: '1rem',
    border: '1px solid #E4E2E4',

    width: '100%',
    height: '52px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    justifyContent: 'center'
}))

const ProfileTest = () => {

    const intl = useIntl()

    const { userProfile, setUserProfile } = useUserProfile();
    const walletStore = useRefreshStore(state => state.wallets)
    const [filteredWallets, setFilteredWallets] = useState<any>()
    const [refresh, setRefresh] = useState<boolean>(false)
    const [showKeyModal, setShowKeyModal] = useState<boolean>(false)
    const [showNewWalletModal, setShowNewWalletModal] = useState<boolean>(false)
    const [privateKey, setPrivateKey] = useState<string>('')
    const [selectedCoin, setSelectedCoin] = useState<string>('CNTP')
    const [coinDropDownOpen, setCoinDropDownOpen] = useState<boolean>(false)
    const [cntp, setCntp] = useState<string>('0');
    const [selectedUser, setSelectedUser] = useState('')
    const [users, setUsers] = useState<IProfile[]>([])
    const [currentName, setCurrentName] = useState<string>()
    const [showMetaMask, setShowMetaMask] = useState(false)
    const [privateKeyArmor, setPrivateKeyArmor] = useState<string>('')
    const [isFaucetLoading, setFaucetLoading] = useState<boolean>(false)
    const [receiverWallet, setReceiverWallet] = useState<string>('')
    const [selectedNetwork, setSelectedNetwork] = useState<any>({
        title: 'BSC',
        name: 'BNB Smart Chain (BEP 20)'
    },)

    const [currentProfile, setCurrentProfile] = useState<any>();
    const [keyID, setKeyID] = useState<string>();
    const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
    const [isSearch, setIsSearch] = useState(false)
    const [transactionHistory, setTransactionHistory] = useState<[]>([])
    const [profiles, setProfiles] = useState<any>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [conetPlatform, setConetPlatform] = useState<any>('')
    const [workerLoading, setWorkerLoading] = useState(0)
    const conet_platform = new platform(setConetPlatform, setWorkerLoading)
    const authKey = useTypedSelector(state => state.appState.pass);
    const [isChangingWallet, setChangingWallet] = useState<boolean>(false);

    const profileDataLoaded = useRef(false)

    useEffect(() => {
        if (userProfile) {
            const _currentProfile = userProfile;
            //const shortID = keyID.substring(0,2) + keyID.substring(2,6).toUpperCase() + '....' + keyID.substring(keyID.length-4,keyID.length).toUpperCase()
            if (selectedCoin === 'CNTP') {
                setCntp(Number(userProfile?.tokens?.cCNTP?.balance).toFixed(6) || 0)
            } else {
                setCntp(Number(userProfile?.tokens?.conet?.balance).toFixed(6) || 0)
            }
            setKeyID(_currentProfile?.keyID);
            setSelectedUser(_currentProfile?.keyID);
            setCurrentProfile(userProfile);
            setUsers(walletStore);
            setCurrentName(_currentProfile?.data?.nickname ? _currentProfile?.data?.nickname : intl.formatMessage({ id: 'platform.ProfileDropdown.CurrentProfileItem.AnonymousUser' }))
        }
    }, [])

    useEffect(() => {
        if (userProfile) {
            const _currentProfile = userProfile;
            //const shortID = keyID.substring(0,2) + keyID.substring(2,6).toUpperCase() + '....' + keyID.substring(keyID.length-4,keyID.length).toUpperCase()
            if (selectedCoin === 'CNTP') {
                setCntp(Number(userProfile?.tokens?.cCNTP?.balance).toFixed(6) || 0)
            } else {
                setCntp(Number(userProfile?.tokens?.conet?.balance).toFixed(6) || 0)
            }
            setKeyID(_currentProfile?.keyID);
            setSelectedUser(_currentProfile?.keyID);
            setCurrentProfile(userProfile);
            setUsers(walletStore);
            setCurrentName(_currentProfile?.data?.nickname ? _currentProfile?.data?.nickname : intl.formatMessage({ id: 'platform.ProfileDropdown.CurrentProfileItem.AnonymousUser' }))
        }
    }, [userProfile])

    useEffect(() => {
        if (walletStore) {
            const _currentProfile = userProfile;
            //const shortID = keyID.substring(0,2) + keyID.substring(2,6).toUpperCase() + '....' + keyID.substring(keyID.length-4,keyID.length).toUpperCase()
            if (selectedCoin === 'CNTP') {
                setCntp(Number(userProfile?.tokens?.cCNTP?.balance).toFixed(6) || 0)
            } else {
                setCntp(Number(userProfile?.tokens?.conet?.balance).toFixed(6) || 0)
            }
            setKeyID(_currentProfile?.keyID);
            setSelectedUser(_currentProfile?.keyID);
            setCurrentProfile(userProfile);
            setUsers(walletStore);
            setCurrentName(_currentProfile?.data?.nickname ? _currentProfile?.data?.nickname : intl.formatMessage({ id: 'platform.ProfileDropdown.CurrentProfileItem.AnonymousUser' }))
        }
    }, [walletStore]);

    const importOneWallet = async () => {
        setIsLoading(true)
        const w = await conet_platform.importWallet(authKey, privateKey)
        if (w.length > 0) {
            setPrivateKey('')
            setRefresh(!refresh)
            toast.success('Wallet imported', { position: "bottom-center", autoClose: 1500 })
            setShowKeyModal(false)
            setIsLoading(false)
        } else {
            toast.error('Import failed, review your private key')
            setIsLoading(false)
        }
    }

    const profileMenuClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setProfileDropdownOpen((prev) => !prev);
    };

    function selectShowedCoin(coin: string) {
        if (coin === 'CNTP') {
            setSelectedCoin('CNTP');
            setCoinDropDownOpen(false)
            setCntp(Number(userProfile.tokens?.cCNTP?.balance).toFixed(6))
        } else {
            setSelectedCoin('CONET Holesky');
            setCoinDropDownOpen(false)
            setCntp(Number(userProfile.tokens?.conet?.balance).toFixed(6))
        }
    }

    useEffect(() => {
        walletStore && walletStore.length > 0 && setProfiles(walletStore)
    }, [refresh, walletStore])

    useEffect(() => {
        if (currentProfile) {
            if (typeof
                //@ts-ignore
                window.ethereum
                !== 'undefined') {
                setShowMetaMask(false)
            }
            setPrivateKeyArmor(currentProfile.privateKeyArmor)
        }
    }, [currentProfile])

    async function generateFaucet(key: string) {
        setFaucetLoading(true)
        const res = await conet_platform.CONETFaucet(key)
        if (res) {
            toast.success('You received Faucets! Wait for tokens update', { position: "bottom-center", autoClose: 1500 })
            setFaucetLoading(false)
        } else {
            toast.error('Please wait 24h until request next faucet', { position: "bottom-center", autoClose: 1500 })
            setFaucetLoading(false)
        }
    }

    const tagColors = [
        "#FF5E5A", "#FFAF00", "#FFDE00", "#00E361", "#009CFF", "#D873FB", "#ACACB0"
    ]

    const [keyProfile, setKeyProfile] = useState<{ index: number; color: string }[]>([]);

    const [filter, setFilter] = useState<string>('');

    const filterWallets = () => {
        let result = profiles.filter((prof: any) => {
            console.log(prof)
            const nameMatches = !!filter.length ? prof.data?.nickname ? prof.data?.nickname.toLowerCase().includes(filter.toLowerCase()) : false : true;
            const tagMatches = !!keyProfile.length ? !!keyProfile.find((keyProfile) => keyProfile.color === prof.data?.tags) : true;

            return nameMatches && tagMatches;
        })
        setFilteredWallets(result)
    }

    useEffect(() => {
        filterWallets();
    }, [filter, keyProfile.length, profiles, refresh])

    const handleIconClick = (key: number, color: string) => {
        const isKeySelected = keyProfile.some((k) => k.index === key && k.color === color);

        if (isKeySelected) {
            setKeyProfile(keyProfile.filter((k) => !(k.index === key && k.color === color)));
        } else {
            setKeyProfile([...keyProfile, { index: key, color }]);
        }
    };

    const ChangeProfile = async (walletStore: any) => {
        setChangingWallet(true);
        await conet_platform.stopMining()
        setCurrentProfile(walletStore);
        store.dispatch(setActiveProfile(walletStore));
        setUserProfile(walletStore)
        useIsMiningRunning.getState().resetMiningStatus()
        setChangingWallet(false);
    }

    const variants = {
        visible: i => ({
            opacity: 1,
            transition: {
                delay: i * 0.1,
            },
        }),
        hidden: { opacity: 0 },
    };

    const list = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
    }

    if (currentProfile) {
        return (
            <Box width={['80vw', '75%']} display="flex" flexDirection="column" gap="32px" maxWidth={'1200px'} padding={['0px', '20px 32px']} alignItems={{ xs: 'start', sm: 'start' }}>
                <Typography variant="h4" sx={{ fontWeight: '400', pb: '16px' }}>
                    {intl.formatMessage({ id: 'platform.profile.header.title' })}
                </Typography>
                <Stack display={'flex'} flexDirection={'row'} width={'100%'}>

                    <Stack display={'flex'} width={'100%'} flexDirection={['column', 'row']} justifyContent={'start'} gap='64px'>
                        <Box width={'192px'}>
                            <Typography variant="h4" sx={{ fontWeight: '500', fontSize: '16px' }}>
                                Manage accounts
                            </Typography>
                            <Typography fontSize={'14px'} fontWeight={400} color={'#989899'} lineHeight={'20px'} letterSpacing={'0.25px'}>
                                Wallet information, avatar and tag organization
                            </Typography>
                        </Box>
                        <Stack display={'flex'} flexDirection={'column'} width={'100%'}>
                            <Box maxWidth={["440px", '100%']} width={['100%', '100%']} display="flex" flexDirection="column" gap="8px">

                                {/* Select Account */}
                                <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '16px' }}>
                                    {intl.formatMessage({ id: 'platform.profile.select.label' })}
                                </Typography>
                                <Box position="relative" display="flex" flexDirection="column" pb="24px">
                                    <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" onClick={profileMenuClick} justifyContent="space-between" padding="16px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#363E59"}>
                                        <Box display="flex" gap="8px" alignItems="center">
                                            {
                                                userProfile && userProfile.data && userProfile?.data?.profileImg ?
                                                    (<img src={`${userProfile?.data?.profileImg}`} height='32px' width='32px' style={{ borderRadius: '100%' }} />) :
                                                    <SvgIcon component={defaultProfilePicture} inheritViewBox sx={{ fontSize: 32 }} />
                                            }

                                            <Box display="flex" flexDirection="column">
                                                <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'}>
                                                    {userProfile?.data?.nickname || intl.formatMessage({ id: 'platform.ProfileDropdown.CurrentProfileItem.AnonymousUser' })}
                                                </Typography>
                                                <Typography variant="body2" fontSize={'12px'} color={'#5F5E60'}>
                                                    {userProfile?.keyID?.substring(0, 2) + userProfile?.keyID?.substring(2, 8).toUpperCase() + '...' + userProfile?.keyID?.substring(userProfile?.keyID?.length - 6, userProfile?.keyID?.length).toUpperCase()}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {
                                            !!userProfile?.data?.tags && (
                                                <Box display="flex" gap="8px" alignItems="center" justifyContent="center">
                                                    <CircleIcon style={{ color: userProfile.data.tags }} />
                                                    <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
                                                </Box>
                                            )
                                        }
                                    </Box>
                                    {isProfileDropdownOpen ? (
                                        <ClickAwayListener onClickAway={() => setProfileDropdownOpen(false)}>
                                            <Box maxWidth={["440px", '100%']} padding={'16px'} overflow="visible" width={'100%'} position="absolute" top="0" display="flex" gap="8px" zIndex={9999} flexDirection="column" borderRadius="16px" boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)" bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"} border={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"}>
                                                {isChangingWallet ? (
                                                    <Box position="absolute" display="flex" flexDirection="column" gap="24px" alignItems="center" justifyContent="center" bgcolor="red" top={0} left={0} right={0} bottom={0} bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"}>
                                                        <Typography variant="subtitle1" fontSize={'18px'} fontWeight={'500'}>
                                                            Changing wallet please wait...
                                                        </Typography>
                                                        <CircularProgress color='success' disableShrink />
                                                    </Box>
                                                ) : null}

                                                <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" onClick={profileMenuClick} justifyContent="space-between" padding="16px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#363E59"}>
                                                    <Box display="flex" gap="8px" alignItems="center">
                                                        {
                                                            userProfile.data && userProfile?.data?.profileImg ?
                                                                (<img src={`${userProfile?.data?.profileImg}`} alt="Imagem Codificada em Base64" height='40px' width='40px' style={{ borderRadius: '100%' }} />) :
                                                                <SvgIcon component={defaultProfilePicture} inheritViewBox sx={{ fontSize: 32 }} />
                                                        }
                                                        <Box display="flex" flexDirection="column">
                                                            <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'}>
                                                                {userProfile?.data?.nickname || intl.formatMessage({ id: 'platform.ProfileDropdown.CurrentProfileItem.AnonymousUser' })}
                                                            </Typography>
                                                            <Typography variant="body2" fontSize={'12px'} color={'#5F5E60'}>
                                                                {userProfile?.keyID?.substring(0, 2) + userProfile?.keyID?.substring(2, 8).toUpperCase() + '...' + userProfile?.keyID?.substring(userProfile?.keyID?.length - 6, userProfile.keyID.length).toUpperCase()}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box display="flex" gap="8px" alignItems="center" justifyContent="center">
                                                        <CircleIcon style={{ color: userProfile?.data && userProfile?.data.tags ? userProfile?.data.tags : '#FFAF00' }} />
                                                        <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
                                                    </Box>
                                                </Box>
                                                <Box id='search-collapse' bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#363E59"} gap="8px" px="8px" borderRadius="50px" height="40px" width={isSearch ? '100%' : '40px'} display="flex" alignItems="center">
                                                    <SvgIcon component={SearchIcon} onClick={() => setIsSearch(!isSearch)} />
                                                    <SearchInput onChange={(e) => setFilter(e.target.value)} style={{ display: isSearch ? 'flex' : 'none', backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#363E59" }} placeholder={intl.formatMessage({ id: 'platform.profile.searchplaceholder', defaultMessage: 'Search here...' })} />
                                                </Box>
                                                <Stack flexDirection={'row'} p={'0px 16px'}>
                                                    <Typography sx={{ fontWeight: '500', fontSize: '14px', mr: '16px' }}>Tags</Typography>
                                                    {tagColors.map((color, key) => (
                                                        <Stack
                                                            gap="8px"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            sx={{ cursor: 'pointer' }}
                                                            key={key}
                                                            onClick={() => handleIconClick(key, color)}
                                                        >
                                                            {keyProfile.some((selectedTag) => selectedTag.color === color) ? (
                                                                <CheckCircleIcon style={{ color }} />
                                                            ) : (
                                                                <CircleIcon style={{ color }} />
                                                            )}
                                                        </Stack>
                                                    ))}
                                                </Stack>
                                                <Box id='scrollable-content' maxHeight="440px" borderTop={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"} borderBottom={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"} display="flex" flexDirection="column" overflow="auto" p={'16px'}>
                                                    <motion.ul
                                                        initial="hidden"
                                                        animate="visible"
                                                        variants={list}
                                                        style={{ listStyle: 'none' }}
                                                    >

                                                        {!filteredWallets ? (
                                                            <Box display="flex" flexDirection="column">
                                                                <Skeleton width={'100%'} height={'230px'} />
                                                                <Skeleton width={'100%'} height={'230px'} />
                                                                <Skeleton width={'100%'} height={'230px'} />
                                                            </Box>) : filteredWallets.map((wallet: any, key: number) => (
                                                                <motion.li custom={key} animate={'visible'} variants={variants} initial='hidden'>
                                                                    <Box key={key} flexShrink={0} style={{ cursor: "pointer" }} height="68px" display="flex" alignItems="center" onClick={() => { ChangeProfile(wallet) }} justifyContent="space-between">


                                                                        <Box display="flex" gap="8px" alignItems="center" >
                                                                            {
                                                                                wallet.data && wallet?.data?.profileImg ?
                                                                                    (<img src={`${wallet?.data?.profileImg}`} alt="Imagem Codificada em Base64" height='40px' width='40px' style={{ borderRadius: '100%' }} />) :
                                                                                    <SvgIcon component={defaultProfilePicture} inheritViewBox sx={{ fontSize: 32 }} />
                                                                            }
                                                                            <Box display="flex" flexDirection="column">
                                                                                <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'}>
                                                                                    {wallet.data && wallet.data.nickname ? wallet.data.nickname : `Account ${key}`}
                                                                                </Typography>
                                                                                <Typography variant="body2" fontSize={'12px'} color={'#5F5E60'}>
                                                                                    {wallet.keyID.substring(0, 2) + wallet.keyID.substring(2, 8).toUpperCase() + '...' + wallet.keyID.substring(wallet.keyID.length - 6, wallet.keyID.length).toUpperCase()}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box display='flex' justifyContent='center' alignItems='center' gap='16px'>
                                                                            {!!wallet?.isNode === true && (
                                                                                <img src={cloud} alt='cloud-icon' height='16px' width='16px' />
                                                                            )}
                                                                            {
                                                                                !!wallet?.data?.tags && (
                                                                                    <Box display="flex" gap="8px" alignItems="center" justifyContent="center">
                                                                                        <CircleIcon style={{ color: wallet.data?.tags }} />
                                                                                    </Box>
                                                                                )
                                                                            }
                                                                        </Box>


                                                                    </Box>
                                                                </motion.li>
                                                            ))}

                                                    </motion.ul>
                                                </Box>
                                                <Box display="flex" flexDirection="column">
                                                    <Box display="flex" padding="16px" gap="8px" style={{ cursor: 'pointer' }} color="#577DFF">
                                                        <SvgIcon component={ControlPointIcon} inheritViewBox sx={{ fontSize: 24 }} />
                                                        <Typography fontSize={'16px'} fontWeight={'700'} onClick={() => { setShowNewWalletModal(!showNewWalletModal); setShowKeyModal(false) }}>
                                                            {intl.formatMessage({ id: 'platform.profile.createwallet', defaultMessage: 'Create another wallet' })}
                                                        </Typography>
                                                    </Box>
                                                    {showNewWalletModal && (
                                                        <Box
                                                            width={['90%', '440px']}
                                                            position='absolute'
                                                            bottom='10vh'
                                                            right='5vw'

                                                        >
                                                            <NewWalletModal
                                                                refresh={refresh}
                                                                setRefresh={setRefresh}
                                                                setShowModal={setShowNewWalletModal}
                                                            />
                                                        </Box>

                                                    )}
                                                    <Box display="flex" padding="16px" gap="8px" style={{ cursor: 'pointer' }} color="#577DFF">
                                                        <SvgIcon component={AccountBalanceWalletOutlinedIcon} inheritViewBox sx={{ fontSize: 24 }} />

                                                        <Typography fontSize={'16px'} fontWeight={'700'} onClick={() => { setShowKeyModal(!showKeyModal); setShowNewWalletModal(false) }}>
                                                            {intl.formatMessage({ id: 'platform.profile.transferwallet', defaultMessage: 'Import wallet' })}
                                                        </Typography>
                                                    </Box>
                                                    {showKeyModal && (
                                                        <Box
                                                            width={['90%', '440px']}
                                                            position='absolute'
                                                            bottom='5vh'
                                                            right='5vw'
                                                            p='1rem'
                                                            bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"}
                                                            boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)"
                                                            border={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"}
                                                            borderRadius="16px"
                                                            gap='8px'
                                                        >
                                                            <Box display='flex' justifyContent='space-between' width={'100%'}>
                                                                <Typography style={{ textTransform: 'uppercase', fontWeight: '700', fontSize: '16px', width: 'fit-content' }}>Import Wallet</Typography>
                                                                <SvgIcon component={localStorage.getItem('mui-mode') === 'light' ? CloseIconBlack : CloseIcon} inheritViewBox onClick={() => setShowKeyModal(false)} />
                                                            </Box>

                                                            <Typography style={{ fontWeight: '400', fontSize: '14px', width: 'fit-content', marginTop: '16px' }}>Insert Private Key</Typography>
                                                            <InputStyle style={{ borderRadius: '8px', padding: '4px' }}>
                                                                <InputBase
                                                                    sx={{ width: '100%', flex: 1, fontSize: '1rem', marginTop: '8px', borderRadius: '8px' }}
                                                                    id="outlined-password-input"
                                                                    type={'text'}
                                                                    placeholder="Wallet private key"
                                                                    value={privateKey}
                                                                    onChange={(e) => setPrivateKey(e.currentTarget.value)}
                                                                />
                                                            </InputStyle>
                                                            <ContainerButton onClick={importOneWallet}>
                                                                <ButtonLabel>{!isLoading ? 'Import Wallet' : <CircularProgress size={24} color="inherit" />}</ButtonLabel>
                                                            </ContainerButton>
                                                        </Box>

                                                    )}
                                                </Box>
                                            </Box>

                                        </ClickAwayListener>
                                    ) : null}
                                </Box>

                            </Box>
                            <InfoCard currentProfile={currentProfile} />
                        </Stack>

                    </Stack>

                </Stack>
                <hr style={{ width: '100%', marginBottom: '40px' }} />
                <TransactionCard receiver={receiverWallet} setReceiver={setReceiverWallet} selectedNetwork={selectedNetwork} setSelectedNetwork={setSelectedNetwork} />
                <hr style={{ width: '100%', marginBottom: '40px' }} />
                <Stack display={'flex'} flexDirection={['column', 'row']} gap={'64px'} width={'100%'} >
                    <Box width={'192px'}>
                        <Typography variant="h4" sx={{ fontWeight: '500', fontSize: '16px' }}>
                            Settings
                        </Typography>
                        <Typography fontSize={'14px'} fontWeight={400} color={'#989899'} lineHeight={'20px'} letterSpacing={'0.25px'}>
                            Choose a preferred theme, change language and password.
                        </Typography>
                    </Box>

                    <SettingsCard />
                </Stack>
                {/*                 <hr style={{ width: '100%', marginBottom: '40px' }} />
                <ProfilePlanCard /> */}

                <Box display="flex" gap="40px" width={'100%'} flexDirection={'column'} alignItems={{ xs: 'center', sm: 'start' }} >

                    <Box /* maxWidth="440px" */ width={'100%'} display="flex" margin={{ xs: "0 auto", md: "0" }} flexDirection="column">
                        <Box style={{ width: '100%', height: '248px' }} display="flex" justifyContent="space-between" padding="16px" borderRadius="16px" border={'1px solid'} bgcolor={localStorage.getItem('mui-mode') === 'light' ? "white" : "#262527"} borderColor={localStorage.getItem('mui-mode') === 'light' ? "#E4E2E4" : "#474648"} flexDirection={'column'}>
                            <Box bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#363E59"} padding={'4px 16px'} borderRadius={!coinDropDownOpen ? '32px' : "12px"} minWidth={'155px'} width={'fit-content'} >
                                {!coinDropDownOpen ? (
                                    <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' onClick={() => setCoinDropDownOpen(true)} style={{ cursor: 'pointer' }} >
                                        <Box>
                                            <Box display="flex" flexDirection="column" width={'100%'}>
                                                <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'}>
                                                    {selectedCoin}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box display="flex" gap="8px" alignItems="center" justifyContent="center">
                                            <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
                                        </Box>
                                    </Box>
                                ) : (
                                    <ClickAwayListener onClickAway={() => setCoinDropDownOpen(false)}>
                                        <Box >
                                            <Box display="flex" flexDirection="column" width={'100%'} onClick={() => selectShowedCoin('CNTP')}>
                                                <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'} style={{ cursor: 'pointer' }} sx={{
                                                    '&:hover': {
                                                        color: '#8DA8FF'
                                                    }
                                                }}>
                                                    CNTP
                                                </Typography>
                                            </Box>
                                            <Box display="flex" flexDirection="column" width={'100%'} onClick={() => selectShowedCoin('CONET Holesky')}>
                                                <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'} style={{ cursor: 'pointer' }} sx={{
                                                    '&:hover': {
                                                        color: '#8DA8FF'
                                                    }
                                                }}>
                                                    CONET Holesky
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </ClickAwayListener>)}
                            </Box>


                            <Box style={{ position: 'relative' }}>
                                {(Number(cntp) < 0.01) &&
                                    (
                                        <div>
                                            <SvgIcon component={ReportProblemIcon} color="warning" fontSize="large" style={{ position: 'absolute', top: '0', right: '0' }} />
                                        </div>
                                    )
                                }

                                <SvgIcon component={CONETIcon} fontSize='large' inheritViewBox style={{ filter: localStorage.getItem('mui-mode') === 'dark' ? "invert(99%) sepia(1%) saturate(0%) hue-rotate(305deg) brightness(105%) contrast(102%)" : "" }} />

                                <Typography variant="subtitle1" fontSize={'32px'} fontWeight={'500'}>
                                    {Number(cntp)} {selectedCoin === 'CONET Holesky' ? 'CONET' : 'CNTP'}
                                </Typography>

                                {selectedCoin === 'CONET Holesky' ? (
                                    <Box display="flex" color="#8DA8FF" gap="8px" alignItems="center">
                                        <Button variant="contained" color="primary" style={{ padding: '8px', borderRadius: '16px', fontWeight: '900', height: '40px', width: '80px' }} onClick={() => generateFaucet(currentProfile.keyID)}>
                                            {!isFaucetLoading ? 'FAUCET' : <CircularProgress size={24} color="inherit" />}
                                        </Button>
                                    </Box>
                                ) : null}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box >
        )
    } else {
        return (
            <>
                <Box width={'100%'} display="flex" flexDirection="column" gap="32px" maxWidth={'1200px'} padding={'20px 32px'} margin={'0 auto'} alignItems={{ xs: 'start', sm: 'start' }}>
                    <Skeleton width={'30%'} height={'100px'} />
                    <Skeleton width={'40%'} height={'90x'} />
                    <Skeleton width={'40%'} height={'250px'} />
                </Box>


            </>

        );
    }
}

export default ProfileTest;
