import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";
import { colors } from "@mui/material";
import { testLocalServer } from "../../../API/index";
import useAppState from "../../../store/appState/useAppState";

import { Grid } from "@mui/material";
import {
	DaemonDownloadButton,
	DaemonDownloadIconWrapper,
	DaemonDownloadLabel,
	DaemonDownloadWrapper,
	OnlyMobileText,
	PageContent,
	PageInternalContent,
} from "./styles";
import { RiArrowDownCircleLine } from "react-icons/ri";
import { LiaApple, LiaWindows } from "react-icons/lia";
import { VscGithub } from "react-icons/vsc";
import { GoArrowUpRight } from "react-icons/go";

const downloadConet = (newValue: any) => {
	switch (newValue) {
		default:
		case 0: {
			return window.open(
				`https://github.com/CoNET-project/seguro-platform/releases/download/0.0.1/CONET-0.61.0.exe`
			);
		}
		case 1: {
			return window.open(
				`https://github.com/CoNET-project/seguro-platform/releases/download/0.0.1/CONET-0.61.0-Apple-M-Series-cpu.dmg`
			);
		}
		case 2: {
			return window.open(
				`https://github.com/CoNET-project/seguro-platform/releases/download/0.0.1/CONET-0.61.0-Inter-CPU.dmg`
			);
		}
		case 3: {
			return window.open(
				`https://github.com/CoNET-project/seguro-platform/releases/tag/0.0.1`
			);
		}
	}
};
export const DownloadArea = () => {
	const intl = useIntl();
	return (
		<Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
			<Typography
				variant="h6"
				sx={{
					textAlign: "center",
					fontSize: "1rem",
					width: `100%`,
					padding: `1.45rem 0 0.65rem`,
					fontWeight: `500`,
				}}
			>
				{intl.formatMessage({ id: "platform.api.daemon.downloadDetail" })}
			</Typography>
			<Grid item md={12} sm={8} xs={4} sx={{ textAlign: "center" }}>
				<DaemonDownloadWrapper>
					<DaemonDownloadButton onClick={() => downloadConet(0)}>
						<DaemonDownloadLabel>
							<LiaWindows fontSize="1.85rem" />
							Windows
						</DaemonDownloadLabel>
						<DaemonDownloadIconWrapper>
							<RiArrowDownCircleLine />
						</DaemonDownloadIconWrapper>
					</DaemonDownloadButton>

					<DaemonDownloadButton onClick={() => downloadConet(1)}>
						<DaemonDownloadLabel>
							<LiaApple fontSize="1.85rem" />
							Apple M
						</DaemonDownloadLabel>
						<DaemonDownloadIconWrapper>
							<RiArrowDownCircleLine />
						</DaemonDownloadIconWrapper>
					</DaemonDownloadButton>

					<DaemonDownloadButton onClick={() => downloadConet(2)}>
						<DaemonDownloadLabel>
							<LiaApple fontSize="1.85rem" />
							Apple
						</DaemonDownloadLabel>
						<DaemonDownloadIconWrapper>
							<RiArrowDownCircleLine />
						</DaemonDownloadIconWrapper>
					</DaemonDownloadButton>

					<DaemonDownloadButton onClick={() => downloadConet(3)}>
						<DaemonDownloadLabel>
							<VscGithub fontSize="1.5rem" /> More
						</DaemonDownloadLabel>
						<DaemonDownloadIconWrapper>
							<GoArrowUpRight />
						</DaemonDownloadIconWrapper>
					</DaemonDownloadButton>
				</DaemonDownloadWrapper>
			</Grid>
		</Grid>
	);
};

const deamon = () => {
	const intl = useIntl();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [verLow, setVerLow] = useState(false);
	const { setlocalDaemon } = useAppState();

	useEffect(() => {
		const fetchData = async () => {
			if (!active) {
				return;
			}

			const testDeamon = async () => {
				testClisk();
			};

			testDeamon().catch((ex) => {
				console.log(`APP useEffect testDeamon error`, ex);
			});
		};

		let active = true;
		fetchData();
		return () => {
			active = false;
		};
	}, []);

	const testClisk = async () => {
		const fetchData = async () => {
			if (!active) {
				return;
			}
			setLoading(true);

			const test = await testLocalServer();
			setLoading(false);
			if (test !== true) {
				setError(true);
				if (test === false) {
					setVerLow(true);
				}
				return setTimeout(() => setError(false), 3000);
			}
			setlocalDaemon(true);
		};

		let active = true;
		fetchData();
		return () => {
			active = false;
		};
	};

	return (
		<Stack
			direction="row"
			justifyContent="center"
			alignItems="center"
			sx={{ width: "100%", height: "100vh", padding: '16px', maxWidth: '1040px' }}
		>
			{
				<PageContent>
					<PageInternalContent>
						<Grid item md={12} sm={8} xs={4} sx={{ textAlign: "center" }}>
							<Typography
								variant="h5"
								sx={{
									textAlign: "center",
									fontSize: `1.65rem`,
									fontWeight: `600`,
								}}
							>
								{intl.formatMessage({ id: "platform.api.daemon.title" })}
							</Typography>
						</Grid>
						{!verLow && (
							<Grid item md={12} sm={8} xs={4} sx={{ textAlign: "center" }}>
								<Typography
									variant="h6"
									sx={{ textAlign: "center", fontSize: "0.8rem" }}
								>
									{intl.formatMessage({ id: "platform.api.daemon.detail" })}
								</Typography>
							</Grid>
						)}
						{verLow && (
							<Grid item md={12} sm={8} xs={4} sx={{ textAlign: "center" }}>
								<Typography
									variant="h6"
									sx={{
										color: colors.red[900],
										textAlign: "center",
										fontSize: "1rem",
									}}
								>
									{intl.formatMessage({ id: "platform.api.daemon.verLow" })}
								</Typography>
							</Grid>
						)}
					</PageInternalContent>
					<PageInternalContent applyBorderTop>
						<DownloadArea />

						<OnlyMobileText
							variant="h6"
							color="text.neutral"
							sx={{
								textAlign: "center",
								fontSize: "0.8rem",
							}}
						>
							{intl.formatMessage({
								id: "platform.api.daemon.mobileNotSupport",
							})}
						</OnlyMobileText>
					</PageInternalContent>
				</PageContent>
			}
		</Stack>
	);
};

export default deamon;
