import { Box, Stack, Typography } from "@mui/material"
import { motion } from 'framer-motion';
import { useState } from "react"

export function Card({ hasContent, index, title, content }: any) {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <Stack>
            <Stack flexDirection={'column'} justifyContent={'flex-start'} gap={'8px'}>
                <Stack width={'100%'}>
                    <motion.nav
                        initial={false}
                        animate={isOpen ? "open" : "closed"}
                        style={{ cursor: 'pointer' }}
                    >
                        <motion.div
                            whileTap={{ scale: hasContent ? 0.97 : 1 }}
                            onClick={() => setIsOpen(state => !state)}
                            style={{ width: '100%', backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#f8f8fa" : "#191919", borderRadius: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '16px 24px', cursor: hasContent ? 'pointer' : 'default' }}
                        >
                            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                <Typography textAlign={'center'} fontSize={'12px'} width={'34px'} fontWeight={500} color={localStorage.getItem('mui-mode') === 'light' ? "#0d0d0d" : "#fff"} padding={'8px'}>{index + 1}</Typography>

                                <Typography color={localStorage.getItem('mui-mode') === 'light' ? "#0d0d0d" : "#fff"} fontSize={'20px'} fontWeight={600}>{title}</Typography>
                            </div>

                            <motion.div
                                variants={{
                                    open: { rotate: 180 },
                                    closed: { rotate: 0 }
                                }}
                                transition={{ duration: 0.05 }}
                                style={{ originY: 0.55 }}
                            >
                                <Typography visibility={hasContent ? 'visible' : 'hidden'} onClick={() => setIsOpen(state => !state)} sx={{ cursor: 'pointer' }}>{isOpen ? '-' : '+'}</Typography>
                            </motion.div>
                        </motion.div>
                        <Box display={isOpen ? 'flex' : 'none'}>
                            {content}
                        </Box>
                    </motion.nav>
                </Stack>
            </Stack>
        </Stack>
    )
}