import { Typography, SvgIcon, IconButton, Tooltip, Skeleton, ClickAwayListener } from "@mui/material";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useTheme } from "styled-components";
import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import { motion } from 'framer-motion';


import {
  Modal,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import {
  CardContent,
  CardContentTop,
  CardSection,
  DashboardCard,
  ClaimBox
} from "../styles/Dashboardpanel";
import { CopyToClipboard } from "../../../../../utilities/utilities";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdOutlineContentCopy } from "react-icons/md";

import { ReactComponent as helpIcon } from '../../../../../assets/icons/Help.svg';
import { ReactComponent as helpIconLight } from '../../../../../assets/icons/Help_light.svg';

import { Stack, Box } from '@mui/material';

import { useTypedSelector } from '../../../../../store/store';
import { listeningMiningHook, platform } from "../../../../../API/platform";
import { NodesRows } from "./NodesRows";
import { useIsMiningRunning } from "./useIsMiningRunning";
import { useUserProfile } from "../../../../../API/useUserProfile";
import { useRefreshStore } from "../../../../../API/useRefreshStore";
import { latestUpdatesWereTriggered } from '../../bridge/latestUpdateInfo';

import { updates } from '../components/UpdateModal/updates';
import ConetianPopup from '../../../ConetianPopup/ConetianPopup';


const formatIpAddr = (addr: string | undefined) => {

  if (!addr) {
    return ''
  }

  let result = addr.substring(0, 2) +
    addr.substring(2, 6).toUpperCase() +
    "...." +
    addr.substring(addr.length - 4, addr.length).toUpperCase();

  return result
}

interface IProfileClaims {
  wallet: string;
  cntp: number;
  isClaiming: boolean;
  profile: any;
  claimStatus: 'none' | 'success' | 'failure';
  lastCheck: number;
}

const DashBoardpanel = () => {
  const { userProfile, setUserProfile } = useUserProfile();
  const walletStore = useRefreshStore(state => state.wallets)

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [profile, setProfile] = useState(walletStore || {});
  const [cntp, setCntp] = useState<string>('0');
  const [loadingError, setLoadingError] = useState(false);
  const theme = useTheme();
  const [isUnlocking, setUnlocking] = useState(false)

  //CONET functions
  const [conetPlatform, setConetPlatform] = useState<any>('')
  const [workerLoading, setWorkerLoading] = useState(0)
  const conet_platform = new platform(setConetPlatform, setWorkerLoading)

  const [openModal, setOpenModal] = useState(false);
  const [openModalClaim, setOpenModalClaim] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);

  const [claimingAsset, setClaimingAsset] = useState("")
  const [claimedAssets, setClaimedAssets] = useState<string[]>([]);
  const [miningStatusHook, setMiningStatusHook] = useState<any>(`{"rate":"0","online":"Calculating...","status":200,"epoch":"0"}`)

  const { colors } = useTheme().ui;
  //const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const profileIndex = useTypedSelector(state => state.appState.activeProfile)
  const setMiningStatus = useIsMiningRunning((state) => state.setMiningStatus);

  //Profile calculation

  const [claimWallDropDown, setClaimAllDropDown] = useState<boolean>(false);

  const [profileClaims, setProfileClaims] = useState<IProfileClaims[]>([])

  const getRefferRate = (num: number) => {
    if (num >= 100) {
      return 3
    }
    if (num < 2) {
      return 1
    }
    return (100 + 2 * num) / 100
  }

  async function unlockCoin(w) {

    if (!isUnlocking) {
      setUnlocking(true)
      const res = await conet_platform.unlock_cCNTP(w)

      if (typeof res == "object") {
        toast.success('Tokens unlocked!', { position: "bottom-center", autoClose: 1500 })
      } else {
        toast.error('Failed to unlock', { position: "bottom-center", autoClose: 1500 })
      }

      setUnlocking(false)
    }

  }
  // function validateClaim() {
  //   return ((userProfile?.tokens?.cBNBUSDT && userProfile?.tokens?.cBNBUSDT?.balance != 0) || (userProfile?.tokens?.cUSDB && userProfile?.tokens?.cUSDB?.balance != 0) || (userProfile?.tokens?.cUSDT && userProfile?.tokens?.cUSDT?.balance != 0))
  // }

  function validateClaim() {
    if (!walletStore || !walletStore?.length) {
      return false
    }
    const _walletStore = walletStore[0]

    if (_walletStore?.tokens?.cBNBUSDT?.balance > 0 ||
      _walletStore?.tokens?.cETH?.balance > 0 ||
      _walletStore?.tokens?.cUSDT?.balance > 0 ||
      _walletStore?.tokens?.cArbEth?.balance > 0 ||
      _walletStore?.tokens?.carbUSDT?.balance > 0 ||
      _walletStore?.tokens?.cBNB?.balance > 0
    ) {
      return true
    }
    return false
  }

  async function handleUpdateModal() {
    const alreadyTriggered = await latestUpdatesWereTriggered(userProfile.keyID.trim(), updates.date);
    setShowUpdateModal(!alreadyTriggered)
  }

  useEffect(() => {
    const updateProfile = () => {
      const keyID = profileIndex && profileIndex?.keyID?.toLowerCase() || "0"
      const profiles = walletStore && walletStore.length > 0 ? walletStore : []
      const index = profiles && profiles.findIndex(n => n.keyID.toLowerCase() === keyID)
      if (index < 0) {
        return
      }
      setProfile(profiles[index])
      //setCurrentProfile(profiles[index])
    }
    updateProfile()
  }, [walletStore]);

  useEffect(() => {
    /* handleUpdateModal(); */
    listeningMiningHook(setMiningStatusHook);
  }, [])



  useEffect(() => {
    async function calculateCurrentBalance() {
      const _cntp = walletStore.reduce ? walletStore.reduce((acc, cur) => acc + parseFloat(cur.tokens?.cCNTP?.balance ? cur.tokens?.cCNTP?.balance : 0), 0.000000) : 0.000000
      if (_cntp) {
        setCntp(Number(_cntp).toFixed(6).toString())
      }
    }
    if (walletStore && walletStore.length > 0) {
      calculateCurrentBalance()
    }
  }, [miningStatusHook, walletStore])

  /*   useEffect(() => {
      async function calculateCurrentBalance() {
        const _cntp = walletStore.length > 0 && walletStore.reduce ? walletStore.reduce((acc, cur) => acc + parseFloat(cur.tokens?.cCNTP?.balance ? cur.tokens?.cCNTP?.balance : 0), 0.000000) : 0.000000
        if (_cntp) {
          setCntp(Number(_cntp).toFixed(6).toString())
        }
      }
      if (walletStore && walletStore.length > 0) {
        calculateCurrentBalance()
      }

    }) */

  const intl = useIntl();

  const reloading = () => {
    setLoadingError(false);
  };

  const claimAsset = async () => {
    setClaimLoading(true)
    let result = await conet_platform.claimToken(profile, claimingAsset)

    if (result == false || result == 'INVALID_DATA') {
      toast.error('Error on claiming token', { position: "bottom-center", autoClose: 1500 })
    } else {
      addClaimedAsset(claimingAsset)
      toast.success('Your token was claimed', { position: "bottom-center", autoClose: 1500 })
    }
    setClaimingAsset("")
    setClaimLoading(false)
  };

  useEffect(() => {
    if (claimingAsset != "") {
      claimAsset()
    }
  }, [claimingAsset]);

  const addClaimedAsset = (newItem: string) => {
    setClaimedAssets(currentItems => [...currentItems, newItem]);
  };

  const claimDropDownClick = () => {
    setClaimAllDropDown((prev) => !prev);
  }

  const ClaimWallet = async (_profile: IProfileClaims) => {
    const cloneProfileClaims = [...profileClaims];
    const found = (cloneProfileClaims.find((x) => x === _profile) as IProfileClaims);
    found.isClaiming = true;
    setProfileClaims(cloneProfileClaims);

    let result = await conet_platform.claimToken(_profile.profile, 'cCNTP') as any;
    found.isClaiming = false;
    found.lastCheck = Date.now();
    if (result == false || result == 'INVALID_DATA') {
      found.claimStatus = 'failure';
    } else {
      addClaimedAsset('cCNTP');
      found.claimStatus = 'success';
    }

    setProfileClaims(cloneProfileClaims);
  }

  const ClaimAll = async () => {
    //await Promise.all(profileClaims.filter((x) => x.cntp > 0).map(profile => ClaimWallet(profile)));
    for await (const profile of profileClaims.filter((x) => x.cntp > 0)) {
      await ClaimWallet(profile);
    }
  }

  //const workerService = getWorkerService();

  const variants = {
    visible: i => ({
      opacity: 1,
      transition: {
        delay: i * 0.1,
      },
    }),
    hidden: { opacity: 0 },
  };

  const list = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  return (
    <DashboardCard>
      <CardSection style={{ minWidth: '65%', zIndex: 99 }}>
        <CardContent gap="0.5rem" align="flex-end">
          <Typography
            sx={{ fontSize: `15px`, fontWeight: `600`, mr: `0.5rem` }}
            color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}
          >
            All wallets
          </Typography>
          <Typography sx={{ fontSize: `15px` }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>
            {intl.formatMessage({ id: "platform.miner.register.walletsCount" })}
            :
          </Typography>
          <Typography sx={{ fontSize: `15px`, ml: `-.25rem` }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>
            {walletStore && walletStore.length ? walletStore.length : <Skeleton variant="rectangular" width={24} height={16} />}
          </Typography>
        </CardContent>

        <CardContentTop gap="1rem" style={{ flexDirection: "column" }}>
          <Box gap={2} style={{ minWidth: '70%', display: "flex" }} sx={{ flexDirection: ['column', 'row', 'row', 'row'] }} >
            <CardContent
              direction="column"
              bg={theme.ui.colors.background.linear}
              padding="1rem"
              flex="7"
              color={theme.ui.colors.text.inverted}
            // maxH="5.5rem"
            >
              <Typography sx={{ fontSize: `15px`, display: 'flex', alignItems: 'center', gap: 1 }}>
                {intl.formatMessage({
                  id: "platform.miner.register.currentBalance",
                })}
                <Tooltip title='The CNTP you already mined'>
                  <SvgIcon component={localStorage.getItem('mui-mode') === 'light' ? helpIcon : helpIconLight} inheritViewBox fontSize="small" />
                </Tooltip>
              </Typography>

              <Typography
                sx={{ fontWeight: `600`, fontSize: `22px`, letterSpacing: `1px`, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
              >
                {loadingError ? (
                  <IconButton color="error" onClick={reloading}>
                    <SyncProblemIcon />
                  </IconButton>
                ) : (
                  // data.wallet.length ? data.wallet.reduce((acc, cur) =>
                  //   acc + parseFloat(cur.tokens?.CNTP?.balance), 0
                  // ).toString() + " CNTP" : <Skeleton variant="rectangular" width={24} height={26} />
                  // totalCrossProfiles > -1 ? (totalCrossProfiles).toString() + " CNTP"
                  Number(cntp) > -1 ? cntp + " CNTP" : <Skeleton variant="rectangular" width={48} height={16} />
                )}
              </Typography>
              <Typography
                sx={{ fontWeight: `400`, fontSize: `12px`, letterSpacing: `1px`, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}

              >
                {loadingError ? (
                  <IconButton color="error" onClick={reloading}>
                    <SyncProblemIcon />
                  </IconButton>
                ) : (
                  walletStore && walletStore.length && walletStore.reduce ? walletStore.reduce((acc, cur) => acc + parseFloat(cur.tokens?.CNTPV1?.balance), 0).toFixed(6).toString() + " CNTP V1" : <Skeleton variant="rectangular" width={24} height={16} />
                )}
              </Typography>
            </CardContent>

            <CardContent
              direction="column"
              bg={theme.ui.colors.background.linear}
              color={theme.ui.colors.text.inverted}
              padding="1rem"
              // maxH="5.5rem"
              flex="2"
              minW="8rem"
              onClick={() => setOpenModal(true)}
              style={{ cursor: "pointer" }}
            >
              <Typography sx={{ fontSize: `15px` }}>
                {intl.formatMessage({ id: "platform.joinUS.miner.cloudTitle" })}
              </Typography>
              <Typography
                sx={{ fontWeight: `600`, fontSize: `24px`, letterSpacing: `1px` }}
              >
                {loadingError ? (
                  <IconButton color="error" onClick={reloading}>
                    <SyncProblemIcon />
                  </IconButton>
                ) : (
                  walletStore ? walletStore?.filter(w => w.isNode === true).length : 0
                )}
              </Typography>
              <Typography sx={{ fontSize: `12px` }}>
                {walletStore ? getRefferRate(walletStore?.filter(w => w.isNode === true).length) : 1}x CNTP
              </Typography>
            </CardContent>
          </Box>



          <Box position="relative" display="flex" flexDirection="column" zIndex={1} height={'68px'}>
            <ClaimBox
              direction="row"
              bg={localStorage.getItem('mui-mode') === 'light' ? "#F3F0F2" : "#1B1B1D"}
              color={localStorage.getItem('mui-mode') === 'light' ? "black" : "white"}
              padding="16px"
              // maxH="5.5rem"
              style={{ justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
              onClick={claimDropDownClick}
            >
              <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} width={'100%'} >

                <Box display={'flex'} flexDirection={'column'} onClick={() => setClaimAllDropDown(!claimWallDropDown)}>
                  <Typography sx={{ fontSize: `12px`, color: localStorage.getItem('mui-mode') === 'light' ? "black" : "white", display: 'flex', alignItems: 'center', gap: 1 }}>
                    Your Wallets
                  </Typography>
                </Box>
                <Stack>
                  {claimWallDropDown ? (
                    <SvgIcon style={{ marginLeft: '4px' }} component={IoIosArrowUp} inheritViewBox fontSize="small" />
                  ) : (
                    <SvgIcon style={{ marginLeft: '4px' }} component={IoIosArrowDown} inheritViewBox fontSize="small" />
                  )}
                </Stack>
              </Box>

              {/* <Box onClick={() => {
                  if(profile.tokens?.cCNTP?.balance > 0){
                    setClaimingAsset('cCNTP')
                  }
                }} display={{cursor:"pointer"}}>


                  {claimLoading && claimingAsset == 'cCNTP' && <Box>
                    <CircularProgress  style={{width:"24px", height:"24px"}}/>
                  </Box>
                  }

                  {!claimLoading && !claimedAssets.includes('cCNTP') &&
                  <Typography sx={{ fontWeight: '700', fontSize: `14px`, color: profile.tokens?.cCNTP?.balance == 0 ? '#787679' : '#3577df' }} >
                    CLAIM NOW
                  </Typography>
                  }

                  {!claimLoading && claimedAssets.includes('cCNTP') &&
                  <Typography sx={{ fontWeight: '700', fontSize: `14px`, color: profile.tokens?.cCNTP?.balance == 0 ? '#787679' : '#3577df' }} >
                    <CheckIcon style={{color: "green"}}/>
                  </Typography>
                  }

              </Box> */}

            </ClaimBox>
            {claimWallDropDown ? (

              <ClickAwayListener onClickAway={() => setClaimAllDropDown(false)}>

                <Box
                  bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F3F0F2" : "#1B1B1D"}
                  color={localStorage.getItem('mui-mode') === 'light' ? "black" : "white"}
                  display="flex"
                  flexDirection="column"
                  position="absolute"
                  right={0}
                  left={0}
                  top={0}
                  pt="24px"
                  padding="1rem"
                  zIndex={9999}
                  borderRadius="0.875rem"
                >

                  <Box display={'flex'} justifyContent="space-between" alignItems="center" onClick={claimDropDownClick} height={'34px'} style={{ cursor: "pointer", zIndex: 9999 }} >
                    <Box display={'flex'} flexDirection={'column'}>
                      <Typography sx={{ fontSize: `12px`, color: localStorage.getItem('mui-mode') === 'light' ? "black" : "white", display: 'flex', alignItems: 'center', gap: 1 }}>
                        Your Wallets
                      </Typography>
                    </Box>
                    <Box display={'flex'}>

                      {!claimWallDropDown ? (
                        <SvgIcon style={{ marginLeft: '4px' }} component={IoIosArrowDown} inheritViewBox fontSize="small" />
                      ) : (
                        <SvgIcon style={{ marginLeft: '4px' }} component={IoIosArrowUp} inheritViewBox fontSize="small" />
                      )}
                    </Box>
                  </Box>

                  <Box height="56px" borderBottom="1px solid #4C4C4C" sx={{
                    display: { xl: 'flex', md: 'flex', sm: 'flex', xs: 'none' },
                    justifyContent: { xl: 'space-between', md: 'space-between', sm: 'space-between', xs: 'normal' }
                  }}>
                    <Box width="164px" display="flex" alignItems="center" px="8px">
                      <Typography sx={{ fontSize: '12px', letterSpacing: `1px`, opacity: 0.5 }}>
                        Address
                      </Typography>
                    </Box>
                    <Box width="164px" display="flex" alignItems="center" px="8px">
                      <Typography sx={{ fontSize: '12px', letterSpacing: `1px`, opacity: 0.5 }}>
                        Balance
                      </Typography>
                    </Box>

                  </Box>

                  <Box flexDirection="column" id='scrollable-content' display="flex" maxHeight="400px" overflow="auto" zIndex={9999}>
                    <motion.ul
                      initial="hidden"
                      animate="visible"
                      variants={list}
                      style={{ listStyle: 'none' }}
                    >
                      {Array.isArray(walletStore) ? walletStore.map((profile, index) => (
                        <motion.li key={index} custom={index} animate={'visible'} variants={variants} initial='hidden'>
                          <Box flexShrink={0} display="flex" height="56px" alignItems="center" justifyContent="space-between" sx={{ flexDirection: { xl: 'row', md: 'row', sm: 'row', xs: 'row-reverse' } }}>
                            <Box width="164px" alignItems="center" px="8px" sx={{ display: { xl: 'flex', md: 'flex', sm: 'flex', xs: 'none' } }}>
                              <Typography sx={{ fontSize: '12px', letterSpacing: `1px` }}>
                                {profile && profile?.keyID?.substring(0, 2) + profile?.keyID?.substring(2, 8).toUpperCase() + '...' + profile?.keyID?.substring(profile.keyID.length - 6, profile.keyID.length).toUpperCase()}
                              </Typography>
                            </Box>
                            {profile && profile?.isNode == true ? (<Typography sx={{ cursor: 'pointer', fontSize: '10px', borderRadius: '8px', padding: '4px 8px' }}>Guardian Node</Typography>) : <></>}
                            {profile && (profile?.tokens?.cCNTP?.unlocked === undefined || profile?.tokens?.cCNTP?.unlocked === false) && profile?.isNode !== true && profile?.tokens?.cCNTP?.balance <= Number(0.0001) ? (<Typography sx={{ cursor: 'pointer', fontSize: '10px', borderRadius: '8px', padding: '4px 8px' }}></Typography>) : <></>}
                            <Box width="164px" alignItems="center" px="8px" sx={{ display: { xl: 'flex', md: 'flex', sm: 'flex', xs: 'none' } }}>
                              <Typography sx={{ fontSize: '12px', letterSpacing: `1px`, opacity: 0.5 }}>
                                {profile && profile?.tokens ? Number(profile?.tokens?.cCNTP?.balance).toFixed(6) : <Skeleton variant="rectangular" width={24} height={16} />} CNTP
                              </Typography>
                            </Box>
                            <Box justifyContent="center" flexDirection="column" sx={{ display: { xl: 'none', md: 'none', sm: 'none', xs: 'flex' } }}>
                              <Typography sx={{ fontSize: '12px', letterSpacing: `1px`, opacity: 0.5 }}>
                                {profile.keyID.substring(0, 2) + profile.keyID.substring(2, 8).toUpperCase() + '...' + profile.keyID.substring(profile.keyID.length - 6, profile.keyID.length).toUpperCase()}
                              </Typography>
                              <Typography sx={{ fontSize: '12px', letterSpacing: `1px` }}>
                                {profile && profile?.tokens ? profile?.tokens?.cCNTP?.balance : <Skeleton variant="rectangular" width={24} height={16} />} CNTP
                              </Typography>
                            </Box>

                          </Box>
                        </motion.li>
                      )
                      ) : (
                        <>
                          <Skeleton width={'100%'} height={'80px'} />
                          <Skeleton width={'100%'} height={'80px'} />
                          <Skeleton width={'100%'} height={'80px'} />
                        </>

                      )}
                    </motion.ul>
                  </Box>
                </Box>

              </ClickAwayListener>

            ) : null}
          </Box>
        </CardContentTop>
      </CardSection>

      <CardSection>
        <CardContent gap="1rem" align="flex-end">
          <CardContent>
            <Typography sx={{ fontSize: `15px`, fontWeight: `600`, mr: '16px' }}
              color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}
            >
              {intl.formatMessage({
                id: "platform.miner.register.currentWallet",
              })}
            </Typography>
            {/*           <CardContent style={{cursor:'pointer'}} gap="0.65rem" align="center"  onClick={() => CopyToClipboard(addr)}> */}

            <div
              style={{
                display: `flex`,
                color: theme.ui.colors.primary,
                cursor: "pointer"
              }}
              onClick={() =>
                CopyToClipboard(profile.keyID)}
            >

              <Typography
                sx={{ fontSize: `15px`, color: theme.ui.colors.primary }}
              >
                {formatIpAddr(profile.keyID)}
              </Typography>


              <MdOutlineContentCopy style={{ marginLeft: '4px' }} />
            </div>
          </CardContent>
        </CardContent>
        <Stack gap={2}>
          <CardContent gap="1rem">

            <CardContent
              direction="column"
              bg={theme.ui.colors.background.linear}
              color={theme.ui.colors.text.inverted}
              padding="1rem"
              flex="5"
            // maxH="5.5rem"
            >
              <Typography sx={{ fontSize: `15px` }}>
                Account Balance
              </Typography>
              <Typography
                sx={{ fontWeight: `600`, fontSize: `22px`, letterSpacing: `1px`, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {loadingError ? (
                  <IconButton
                    color="error"
                    sx={{ marginTop: "-0.5rem" }}
                    onClick={reloading}
                  >
                    <SyncProblemIcon />
                  </IconButton>
                ) : (
                  userProfile && userProfile.tokens?.cCNTP?.balance ? Number(userProfile.tokens?.cCNTP?.balance).toFixed(6).toString() + " CNTP" : "0 CNTP"
                )}
              </Typography>
              <Typography
                sx={{ fontWeight: `400`, fontSize: `12px`, letterSpacing: `1px` }}
              >
                {loadingError ? (
                  <IconButton
                    color="error"
                    sx={{ marginTop: "-0.5rem" }}
                    onClick={reloading}
                  >
                    <SyncProblemIcon />
                  </IconButton>
                ) : (
                  `${profile?.tokens?.CNTPV1?.balance ? profile.tokens?.CNTPV1?.balance?.toString() : 0} CNTP V1 `

                )}
              </Typography>

            </CardContent>
          </CardContent>

          <ClaimBox
            direction="row"
            bg={localStorage.getItem('mui-mode') === 'light' ? "#F3F0F2" : "#1B1B1D"}
            color={localStorage.getItem('mui-mode') === 'light' ? "black" : "white"}
            padding="1.4rem 1rem"
            flex="7"
            // maxH="5.5rem"
            style={{ height: '80px', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Box display={'flex'} flexDirection={'column'}>
              {validateClaim() ?
                <Typography sx={{ fontSize: `16px`, color: '#787679', display: 'flex', alignItems: 'center', gap: 1 }}>
                  You have assets to claim!
                </Typography>
                :
                <Typography sx={{ fontSize: `16px`, color: '#787679', display: 'flex', alignItems: 'center', gap: 1 }}>
                  You have no assets to claim
                </Typography>
              }
            </Box>

            {validateClaim() ?
              <Typography sx={{ fontWeight: '700', fontSize: `14px`, cursor: 'pointer', color: (localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF") }} onClick={() => setOpenModalClaim(true)}>
                CLAIM NOW
              </Typography>
              : <></>
            }

          </ClaimBox>
        </Stack>

        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          sx={{ margin: `auto`, bgcolor: `#'#00000066'` }}
        >
          <Stack
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: `896px`,
              maxWidth: '90vw',
              maxHeight: `80%`,
              overflow: `hidden auto`,
              bgcolor: localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#111113",
              borderRadius: `1rem`,
              boxShadow: 24,
              p: 4,
              zIndex: 100,
            }}>
            <TableContainer
              sx={{
                border: `1px solid black`,
                borderColor: colors.border.light,
                borderRadius: `1rem`,
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell><span style={{ color: colors.text.neutral }}>Address</span> </TableCell>
                    <TableCell><span style={{ color: colors.text.neutral }}>Status</span></TableCell>
                    <TableCell><span style={{ color: colors.text.neutral }}>IP Address</span></TableCell>
                    <TableCell><span style={{ color: colors.text.neutral }}>Region</span></TableCell>
                    <TableCell><span style={{ color: colors.text.neutral }}>CNTP</span> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {walletStore && walletStore.length > 0 && walletStore.filter(w => w.isNode === true).map((row, i) => (
                    // <NodesRows keyId={row?.keyID} ipAddress={row?.nodeIP_address} region={locationMap && locationMap[row?.nodeRegion]?.toString()} ccntp={row.tokens?.cCNTP?.balance} key={i} />
                    <NodesRows keyId={row?.keyID} ipAddress={row?.nodeIP_address} region={row?.nodeRegion} ccntp={row.tokens?.cCNTP?.balance} key={i} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Modal>


        <Modal
          open={openModalClaim}
          onClose={() => setOpenModalClaim(false)}
          sx={{ margin: `auto`, bgcolor: `#'#00000066'` }}
        >
          <Stack
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: `90%`,
              maxWidth: '450px',
              maxHeight: `80%`,
              overflow: `hidden auto`,
              bgcolor: localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#111113",
              borderRadius: `1rem`,
              boxShadow: 24,
              p: 4,
            }}>
            <TableContainer
              sx={{
                border: `1px solid black`,
                borderColor: colors.border.light,
                borderRadius: `1rem`,
                width: '100%'
              }}
            >
              <Table sx={{ width: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell><span style={{ color: colors.text.neutral }}>Available</span> </TableCell>
                    <TableCell><span style={{ color: colors.text.neutral }}></span></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {/* {profile?.tokens?.cCNTP && profile?.tokens?.cCNTP.balance != 0  &&
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell style={{ textAlign: 'left' }}>
                          ${profile?.tokens?.cCNTP.balance} {profile?.tokens?.cCNTP.name}
                        </TableCell>

                        {!claimLoading && !claimedAssets.includes('cCNTP') &&
                          <TableCell onClick={()=>{setClaimingAsset('cCNTP')}} style={{ cursor:'pointer', textAlign: 'center', color:(localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                              Claim Now
                          </TableCell>
                        }

                        {claimLoading &&
                          <TableCell style={{ cursor:'pointer', textAlign: 'center', color:(localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                              <CircularProgress style={{width:"14px", height:"14px"}} />
                          </TableCell>
                        }

                        {!claimLoading && claimedAssets.includes('cCNTP') &&
                          <TableCell style={{ cursor:'pointer', textAlign: 'center', color:(localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                              <CheckIcon style={{color: "green"}}/>
                          </TableCell>
                        }

                    </TableRow>
                  } */}

                  {profile?.tokens?.cBNBUSDT && profile?.tokens?.cBNBUSDT?.balance != 0 &&
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell style={{ textAlign: 'left' }}>
                        {profile?.tokens?.cBNBUSDT?.balance} {profile?.tokens?.cBNBUSDT?.name}
                      </TableCell>

                      {!claimLoading && !claimedAssets.includes('cBNBUSDT') &&
                        <TableCell onClick={() => { setClaimingAsset('cBNBUSDT') }} style={{ cursor: 'pointer', textAlign: 'center', color: (localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                          Claim Now
                        </TableCell>
                      }

                      {claimLoading &&
                        <TableCell style={{ cursor: 'pointer', textAlign: 'center', color: (localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                          <CircularProgress style={{ width: "14px", height: "14px" }} />
                        </TableCell>
                      }

                      {!claimLoading && claimedAssets.includes('cBNBUSDT') &&
                        <TableCell style={{ cursor: 'pointer', textAlign: 'center', color: (localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                          <CheckIcon style={{ color: "green" }} />
                        </TableCell>
                      }

                    </TableRow>
                  }

                  {profile?.tokens?.cBNB && profile?.tokens?.cBNB?.balance != 0 &&
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell style={{ textAlign: 'left' }}>
                        {profile?.tokens?.cBNB?.balance} {profile?.tokens?.cBNB?.name}
                      </TableCell>

                      {!claimLoading && !claimedAssets.includes('cBNB') &&
                        <TableCell onClick={() => { setClaimingAsset('cBNB') }} style={{ cursor: 'pointer', textAlign: 'center', color: (localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                          Claim Now
                        </TableCell>
                      }

                      {claimLoading &&
                        <TableCell style={{ cursor: 'pointer', textAlign: 'center', color: (localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                          <CircularProgress style={{ width: "14px", height: "14px" }} />
                        </TableCell>
                      }

                      {!claimLoading && claimedAssets.includes('cBNB') &&
                        <TableCell style={{ cursor: 'pointer', textAlign: 'center', color: (localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                          <CheckIcon style={{ color: "green" }} />
                        </TableCell>
                      }

                    </TableRow>
                  }

                  {profile?.tokens?.cETH && profile?.tokens?.cETH?.balance != 0 &&
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell style={{ textAlign: 'left' }}>
                        {profile?.tokens?.cETH?.balance} {profile?.tokens?.cETH?.name}
                      </TableCell>

                      {!claimLoading && !claimedAssets.includes('cETH') &&
                        <TableCell onClick={() => { setClaimingAsset('cETH') }} style={{ cursor: 'pointer', textAlign: 'center', color: (localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                          Claim Now
                        </TableCell>
                      }

                      {claimLoading &&
                        <TableCell style={{ cursor: 'pointer', textAlign: 'center', color: (localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                          <CircularProgress style={{ width: "14px", height: "14px" }} />
                        </TableCell>
                      }

                      {!claimLoading && claimedAssets.includes('cETH') &&
                        <TableCell style={{ cursor: 'pointer', textAlign: 'center', color: (localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                          <CheckIcon style={{ color: "green" }} />
                        </TableCell>
                      }

                    </TableRow>
                  }

                  {profile?.tokens?.cArbETH && profile?.tokens?.cArbETH?.balance != 0 &&
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell style={{ textAlign: 'left' }}>
                        {profile?.tokens?.cArbETH?.balance} {profile?.tokens?.cArbETH?.name}
                      </TableCell>

                      {!claimLoading && !claimedAssets.includes('cArbETH') &&
                        <TableCell onClick={() => { setClaimingAsset('cArbETH') }} style={{ cursor: 'pointer', textAlign: 'center', color: (localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                          Claim Now
                        </TableCell>
                      }

                      {claimLoading &&
                        <TableCell style={{ cursor: 'pointer', textAlign: 'center', color: (localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                          <CircularProgress style={{ width: "14px", height: "14px" }} />
                        </TableCell>
                      }

                      {!claimLoading && claimedAssets.includes('cArbETH') &&
                        <TableCell style={{ cursor: 'pointer', textAlign: 'center', color: (localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                          <CheckIcon style={{ color: "green" }} />
                        </TableCell>
                      }

                    </TableRow>
                  }

                  {profile?.tokens?.cArbUSDT && profile?.tokens?.cArbUSDT?.balance != 0 &&
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell style={{ textAlign: 'left' }}>
                        {profile?.tokens?.cArbUSDT?.balance} {profile?.tokens?.cArbUSDT?.name}
                      </TableCell>

                      {!claimLoading && !claimedAssets.includes('cArbUSDT') &&
                        <TableCell onClick={() => { setClaimingAsset('cArbUSDT') }} style={{ cursor: 'pointer', textAlign: 'center', color: (localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                          Claim Now
                        </TableCell>
                      }

                      {claimLoading &&
                        <TableCell style={{ cursor: 'pointer', textAlign: 'center', color: (localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                          <CircularProgress style={{ width: "14px", height: "14px" }} />
                        </TableCell>
                      }

                      {!claimLoading && claimedAssets.includes('cArbUSDT') &&
                        <TableCell style={{ cursor: 'pointer', textAlign: 'center', color: (localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                          <CheckIcon style={{ color: "green" }} />
                        </TableCell>
                      }

                    </TableRow>
                  }

                  {profile?.tokens?.cUSDT && profile?.tokens?.cUSDT?.balance != 0 &&
                    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell style={{ textAlign: 'left' }}>
                        {profile?.tokens?.cUSDT?.balance} {profile?.tokens?.cUSDT?.name}
                      </TableCell>

                      {!claimLoading && !claimedAssets.includes('cUSDT') &&
                        <TableCell onClick={() => { setClaimingAsset('cUSDT') }} style={{ cursor: 'pointer', textAlign: 'center', color: (localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                          Claim Now
                        </TableCell>
                      }

                      {claimLoading &&
                        <TableCell style={{ cursor: 'pointer', textAlign: 'center', color: (localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                          <CircularProgress style={{ width: "14px", height: "14px" }} />
                        </TableCell>
                      }

                      {!claimLoading && claimedAssets.includes('cUSDT') &&
                        <TableCell style={{ cursor: 'pointer', textAlign: 'center', color: (localStorage.getItem('mui-mode') == 'light' ? "#3577df" : "#79F8FF"), fontWeight: '700' }}>
                          <CheckIcon style={{ color: "green" }} />
                        </TableCell>
                      }

                    </TableRow>
                  }

                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Modal>



      </CardSection>

      <ConetianPopup />
      {/* <UpdateModal open={showUpdateModal} close={() => setShowUpdateModal(false)} /> */}

    </DashboardCard >
  );
};
export default DashBoardpanel;
