import {
  useColorScheme, styled
} from "@mui/material-next/styles";
import {
  useState,
  useEffect,
  SyntheticEvent
} from "react";
import Stack from "@mui/material/Stack";
import { Tabs, Tab } from "@mui/material-next";
import AppBar from "@mui/material/AppBar";
import type { SxProps, Theme } from "@mui/material/";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { Locale } from "../../../../localization/types";
import {
  Menu, Typography,
  useTheme,
  useMediaQuery
} from "@mui/material";
import Box from "@mui/material/Box";
import useAppState from "../../../../store/appState/useAppState";
import {
  setDAPPOpen
} from "../../../../store/appState/appStateActions";
import SvgIcon from "@mui/material/SvgIcon";
import { LogoImage } from "../../../UI/Logo/Logo";
import LocalLaundryServiceIcon from "@mui/icons-material/LocalLaundryService";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VpnLockIcon from "@mui/icons-material/VpnLock";
import DarkIcon from "@mui/icons-material/DarkModeOutlined";
import LightIcon from "@mui/icons-material/LightModeOutlined";
import { US, CN, JP } from "country-flag-icons/react/3x2";
import NoDaemon from "../../NoDaemon/index";
import Proxy from "../CONET-Proxy/index";
import Miner from "../miner/index";
import HubIcon from "@mui/icons-material/Hub";
import NodeExplorer from "../nodeExplorer/nodeExplorer";
import Container from "@mui/material/Container";
import store, { useTypedSelector } from "../../../../store/store";
import CloudNode from "../CloudNode/CloudNode";
import { FormattedMessage, useIntl } from "react-intl";
import Conet_Logo_Dark from "../../../../assets/images/conet-logo-dark.png";
import Conet_Logo_Light from "../../../../assets/images/conet-logo-light.png";
import { ReactComponent as IconDashboard } from "../../../../assets/images/icon-dashboard.svg";
import { ReactComponent as IconApps } from "../../../../assets/images/icon-apps.svg";
import { ReactComponent as IconLeaderboard } from "../../../../assets/images/icon-leaderboard.svg";
import { ReactComponent as ConetAvatar } from "../../../../assets/images/con-avatar.svg";

import { ReactComponent as IconArrowRight } from "../../../../assets/images/arrow-right.svg";
import { ReactComponent as IconArrowLeft } from "../../../../assets/images/arrow-left.svg";


import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ProfileTest from "../profileTEST/index";
import { WelcomeToConet } from "./WelcomeToConet";
import { IProfile } from "../profileTEST/interface/profile.interface";
import { listeningVersionHook } from "../../../../API/platform";

import FooterPlatform from './footerPlatform';
import { motion } from 'framer-motion';
import { Bridge } from "../bridge";
import { useUserProfile } from "../../../../API/useUserProfile";

interface StyledTabsProps {
  children?: React.ReactNode;
  value?: number;
  onChange?: (event: React.SyntheticEvent, newValue: number) => void;
}

const StackContainer = styled(Stack)(() => ({
  width: "100%",
  height: '100vh'
}));

interface StyledTabProps {
  label?: string;
  icon?:
  | string
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  sx?: SxProps<Theme>;
}

interface NetworkInterface {
  isCollapsed: boolean;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    orientation="vertical"
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
  },
});

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  "&.Mui-selected": {},
  "&.Mui-focusVisible": {},
}));

const LogoDark = styled(Box)(({ theme }) => ({
  width: "48px",
  height: "48px",
  backgroundImage: `url(${Conet_Logo_Dark})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
}));

const LogoLight = styled(Box)(({ theme }) => ({
  width: "48px",
  height: "48px",
  backgroundImage: `url(${Conet_Logo_Light})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
}));

const MenuOption = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  cursor: "pointer",
  padding: '8px 16px',
  borderRadius: '16px'
  // "&:hover": {
  //   color: "#3399FF",
  //   fontWeight: "bold",
  // },
}));

const ThemeOption = styled(Box)(({ theme }) => ({
  width: "32px",
  height: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "100%",
  cursor: "pointer",
}));

//display="flex" justifyContent="space-between" alignItems="center"
const LanguageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
}));

const NetworkBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "50px",
  padding: "0.25rem 1rem",
  lineHeight: "24px",
  fontSize: "16px",
  justifyContent: "space-between",
  cursor: 'pointer'
}));

//display="flex" gap="0.5rem" alignItems="center" p="1rem" borderRadius="1rem"
const ProfileBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "0.5rem",
  alignItems: "center",
  padding: "1rem",
  borderRadius: "1rem",
  cursor: 'pointer'
}));

type action = {
  icon: JSX.Element;
  name: Locale;
  description: string;
};

const actions: action[] = [
  {
    icon: <SvgIcon component={JP} inheritViewBox />,
    name: "ja-JP",
    description: "中文 (Soon)",
  },
  {
    icon: <SvgIcon component={CN} inheritViewBox />,
    name: "zh-CN",
    description: "日本語 (Soon)",
  },

  // { icon: <SvgIcon component={TW} inheritViewBox/>, name: 'zh-TW' },

  {
    icon: <SvgIcon component={US} inheritViewBox />,
    name: "en-US",
    description: "English",
  },
];

const showLocationIcon = (locale: Locale) => {
  switch (locale) {
    default:
    case "en-US": {
      return <SvgIcon component={US} inheritViewBox />;
    }
    case "ja-JP": {
      return <SvgIcon component={JP} inheritViewBox />;
    }
    case "zh-CN": {
      return <SvgIcon component={CN} inheritViewBox />;
    }
  }
};

const NetworkSwitcher = (props: NetworkInterface) => {
  const { mode, setMode } = useColorScheme();
  const { setTheme, theme } = useAppState()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const ModalSelector = styled(Box)(({ theme }) => ({
    cursor: "pointer",
    height: "40px!important",
    lineHeight: '32px',
    alignItems: "center",
    display: "flex",
    borderRadius: "16px",
    justifyContent: "space-between",
    "&:hover": {
      background: mode === "light" ? "#F6F8FF" : "#363E59",
    },
  }));

  useEffect(() => {
    if (localStorage.getItem('mui-mode') == 'light') {
      setMode(`light`);
      setTheme("Light");
    } else {
      setMode(`dark`);
      setTheme("Dark");
    }
  }, [mode]);


  const networkMenuClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleCloseDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <Box position="relative" flexGrow={1}>

      {/* <NetworkBox
        onClick={networkMenuClick}
        bgcolor={mode === "light" ? "#F6F8FF" : "#303032"}
      >

        <SvgIcon
          cursor="pointer"
          component={IconNetworkGlobe}
          inheritViewBox
          sx={{ fontSize: 22 }}
        />
        {props.isCollapsed ? null :
          <Typography fontWeight="bold">
            <FormattedMessage
              id="sidebar.mainnetwork"
              defaultMessage="Conet Network" />
          </Typography>
        }

        <SvgIcon
          component={ExpandMoreIcon}
          inheritViewBox
          sx={{ fontSize: 16 }}
        />
      </NetworkBox> */}
      {isDropdownOpen ? (
        <ClickAwayListener onClickAway={handleCloseDropdown}>
          <Box
            width="440px"
            maxHeight="208px"
            position="absolute"
            top={0}
            left={props.isCollapsed ? '60px' : "190px"}
            zIndex={9999}
            bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"}
            display="flex"
            flexDirection="column"
            borderRadius="16px"
            boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)"
            p="1rem"
            gap="32px"
          >
            <Stack justifyContent="space-between" direction="row">
              <Typography fontWeight="bold">
                <FormattedMessage
                  id="sidebar.networktext"
                  defaultMessage="Network"
                />
              </Typography>
              <SvgIcon
                cursor="pointer"
                onClick={handleCloseDropdown}
                component={CloseIcon}
                inheritViewBox
                sx={{ fontSize: 16 }}
              />
            </Stack>
            <Stack direction="column">
              <ModalSelector px="1rem">
                Conet Network
                <SvgIcon
                  cursor="pointer"
                  component={CheckIcon}
                  inheritViewBox
                  sx={{ fontSize: 24 }}
                />
              </ModalSelector>

              <Box display="flex" gap="8px" color="#577DFF" paddingTop="32px">
                <SvgIcon
                  component={AddCircleOutlineIcon}
                  inheritViewBox
                  sx={{ fontSize: 24 }}
                />
                <Typography fontWeight="bold" fontSize="16px">
                  <FormattedMessage
                    id="sidebar.networktext"
                    defaultMessage="Add network"
                  />
                </Typography>
              </Box>
            </Stack>
          </Box>
        </ClickAwayListener>
      ) : null}
    </Box>
  );
};

const LanguageSwitcher = (isCollapsed: any) => {
  const intl = useIntl();
  const { locale, setLocale } = useAppState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { mode, setMode } = useColorScheme();

  const LanguageSelector = styled(Box)(({ theme }) => ({
    cursor: "pointer",
    height: "40px",
    alignItems: "center",
    display: "flex",
    borderRadius: "16px",
    justifyContent: "space-between",
    "&:hover": {
      background: mode === "light" ? "#F6F8FF" : "#363E59",
    },
  }));

  const languageMenuClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleCloseDropdown = () => {
    setIsDropdownOpen(false);
  };

  const selectLanguage = (locale: Locale) => {
    setLocale(locale);
    setIsDropdownOpen(false);
  };

  return (
    <Box position="relative" flexGrow={1}>
      <LanguageBox onClick={languageMenuClick}>
        <Box>{isCollapsed ? locale : "Language"}</Box>
        <SvgIcon
          cursor="pointer"
          component={ExpandMoreIcon}
          inheritViewBox
          sx={{ fontSize: 16 }}
        />
      </LanguageBox>

      {isDropdownOpen ? (
        <ClickAwayListener onClickAway={handleCloseDropdown}>
          <Box
            width="440px"
            height="208px"
            position="absolute"
            bottom="0px"
            left={isCollapsed ? "50px" : "150px"}
            zIndex={9999}
            bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#262527"}
            border={'1px solid white'}
            display="flex"
            flexDirection="column"
            borderRadius="16px"
            boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)"
            p="1rem"
            gap="32px"
          >
            <Stack justifyContent="space-between" direction="row">
              <Typography fontWeight="bold" >
                <FormattedMessage
                  id="sidebar.languagetext"
                  defaultMessage="Language"
                />
              </Typography>
              <SvgIcon
                cursor="pointer"
                onClick={handleCloseDropdown}
                component={CloseIcon}
                inheritViewBox
                sx={{ fontSize: 16 }}
              />
            </Stack>
            <Stack direction="column">
              {actions.map((language, index) => (
                <LanguageSelector
                  key={index}
                  px="1rem"

                >
                  {language.description}
                  {locale === language.name ? (
                    <SvgIcon
                      cursor="pointer"
                      component={CheckIcon}
                      inheritViewBox
                      sx={{ fontSize: 24 }}
                    />
                  ) : null}
                </LanguageSelector>
              ))}
            </Stack>
          </Box>
        </ClickAwayListener>
      ) : null}
    </Box>
  );
};

type MobileSIdeBarProps = {
  handleChange: (event: SyntheticEvent | null, newValue: number) => any;
  profile?: any,
  currentProfile?: any,
};
const MobileSidebar: React.FC<MobileSIdeBarProps> = ({ handleChange, profile, currentProfile }) => {
  const { isUnlocked } = useAppState();
  const { mode, setMode } = useColorScheme();

  const MenuItem = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    fontSize: "12px",
    alignItems: "center",
    justifyContent: "center",
    height: "72px",
    gap: "4px",
    flexGrow: 1,
    cursor: "pointer",
    "&:hover": {
      color: "#3399FF",
      fontWeight: "bold",
    },
    zIndex: 99,
  }));

  return isUnlocked ? (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"}
      height="72px"
      zIndex={9999}
      display={{
        xs: "flex",
        sm: "flex",
        md: "flex",
        xm: "none",
        lg: "none",
        xl: "none",
      }}
      borderTop={localStorage.getItem('mui-mode') === "light" ? "1px solid #E4E2E4" : "1px solid #474648"}
      justifyContent="space-between"
    >
      <MenuItem onClick={(event) => handleChange(event, 0)}>
        <SvgIcon
          component={IconDashboard}
          inheritViewBox
          sx={{ fontSize: 24 }}
        />
        <FormattedMessage
          id="sidebar.dashboard"
          defaultMessage="Dashboard"
        />
      </MenuItem>
      {/* <MenuItem
      // onClick={(event) => handleChange(event, 2)}
      >
        <SvgIcon
          component={IconExplorer}
          sx={{ fontSize: 24 }}
        />
        <FormattedMessage id="sidebar.explorer" defaultMessage="Explorer" />
      </MenuItem> */}
      <MenuItem
        onClick={(event) => handleChange(event, 1)}
      >
        <SvgIcon
          component={IconLeaderboard}
          sx={{ fontSize: 24 }}
        />
        <FormattedMessage id="sidebar.leaderboard" defaultMessage="Leaderboard" />
      </MenuItem>
      <MenuItem onClick={(event) => handleChange(event, 4)}>
        <SvgIcon
          component={IconApps}
          /* onClick apps */ inheritViewBox
          sx={{ fontSize: 24 }}
        />
        <FormattedMessage id="sidebar.apps" defaultMessage="Apps" />
      </MenuItem>
      <MenuItem onClick={(event) => handleChange(event, 3)}>
        {profile?.data && profile?.data?.profileImg ? (
          <img
            src={` ${profile?.data?.profileImg}`}
            alt="Imagem Codificada em Base64"
            height="20px"
            width="20px"
            style={{ borderRadius: "100%" }}
          />
        ) : (
          <SvgIcon
            component={ConetAvatar}
            inheritViewBox
            sx={{ fontSize: 32 }}
          />
        )}
        <Typography fontWeight={500} fontSize="12px">
          {profile?.data?.nickname || "Anonymous User"}
        </Typography>
      </MenuItem>
    </Box>
  ) : null;
};

const DashBoard = () => {
  const {
    hasContainer,
    isUnlocked,
    showMiner,
    showAppStore,
    setShowMiner,
    localDaemon,
    isInitializing,
    setShowAppStore,
    locale,
    isNodeExplorerOpen,
    isModalOpen,
    setLocale,
    setIsNodeExplorerOpen,
    dAPPOpen,
  } = useAppState();
  const intl = useIntl();

  const [menuValue, setMenuValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement | Element>();
  const openMenu = Boolean(anchorEl);
  const { mode, setMode } = useColorScheme();
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);

  const ShowApp = () => {
    // if (!hasContainer) {
    //   return (
    //     <CreateWallet />
    //   )
    // }
    // if (!isUnlocked) {
    //   return (
    //     <></>
    //     // <UnLockWallet />
    //   )
    // }

    switch (dAPPOpen) {
      case "miner": {
        return (
          <Miner />
        );
      }

      case "proxy": {
        if (!localDaemon) {
          return <Proxy />;
        }
        return <Proxy />;
      }

      case "cloudNode": {
        return <CloudNode />;
      }

      case "profileTEST": {
        return <ProfileTest />;
      }

      case "apps": {
        return <NoDaemon />;
      }

      case "bridge": {
        if (localDaemon) {
          return <Bridge />;
        } else {
          return <NoDaemon />;
        }

      }

      default: {
        return <NodeExplorer />;
      }
    }
  };

  const MenuSideBar = () => {
    const { setTheme, theme } = useAppState()
    const [currentProfile, setCurrentProfile] = useState<IProfile>();
    const [currentName, setCurrentName] = useState<string>()
    const [isCollapsed, setCollapsed] = useState<boolean>(false)
    const profile = useTypedSelector(state => state.appState.activeProfile)
    const [profileVer, setProfileVer] = useState<number>(0)
    const authKey = useTypedSelector(state => state.appState.pass)

    const { userProfile, setUserProfile } = useUserProfile();

    useEffect(() => {
      listeningVersionHook(setProfileVer)
    })

    useEffect(() => {
      setCurrentProfile(userProfile)

    }, [profile])


    //For some reason this is returning undefined
    //const { data } = getWorkerService()

    /* useEffect(() => {
      if (data) {
          const _currentProfile = (data.profiles as IProfile[]).filter((n:IProfile)=> n.isPrimary)[0];
          setCurrentProfile(_currentProfile);
          setCurrentName(_currentProfile.nickName ? _currentProfile.nickName : intl.formatMessage({id: 'platform.ProfileDropdown.CurrentProfileItem.AnonymousUser'}))
      }
    }, [data]); */

    const animeCONET = () => {
      return (
        <LogoImage size={40} color={mode === "light" ? "#48473a" : "#e5e2d9"} />
      );
    };
    const handleChange = (event: SyntheticEvent | null, newValue: number) => {
      if (event !== null) {
        switch (newValue) {
          case 0: {
            setMenuValue(newValue);
            if (dAPPOpen === "miner") {
              return;
            }

            return store.dispatch(setDAPPOpen("miner"));
          }
          case 1: {
            setMenuValue(newValue);
            if (dAPPOpen === "proxy") {
              return;
            }

            return store.dispatch(setDAPPOpen("proxy"));
          }

          case 3: {
            setMenuValue(newValue);
            if (dAPPOpen === "profileTEST") {
              return;
            }

            return store.dispatch(setDAPPOpen("profileTEST"));
          }
          case 4: {
            setMenuValue(newValue);
            if (dAPPOpen === "apps") {
              return;
            }


            return store.dispatch(setDAPPOpen("bridge"));
          }

          case 5: {
            setMenuValue(newValue);
            if (dAPPOpen === "bridge") {
              return;
            }


            return store.dispatch(setDAPPOpen("bridge"));
          }

          default: {
            setMenuValue(newValue);
            if (dAPPOpen === "nodes") {
              return;
            }
            return store.dispatch(setDAPPOpen("nodes"));
          }
        }
      }
    };

    const StyledTabsMobile = () => {
      const intl = useIntl();
      const [ready, setReady] = useState(false);
      const [scrollDir, setScrollDir] = useState(1);
      const theme = useTheme();

      return (
        <AppBar
          position="fixed"
          enableColorOnDark
          id="conet_AppBar"
          sx={{
            top: "auto",
            bottom: 0,
            color: mode === "light" ? "#48473a" : "#e5e2d9",
            display: {
              xs: "flex",
              sm: "none",
              md: "none",
              xm: "none",
              lg: "none",
              xl: "none",
            },
            opacity: scrollDir,
            backgroundColor: mode === "light" ? "#f0eddd" : "#2d2d1e",
          }}
        >
          <Toolbar>
            {isUnlocked && (
              <>
                <IconButton
                  onClick={() => {
                    store.dispatch(setDAPPOpen("miner"));
                  }}
                  sx={{ opacity: showMiner ? "1" : "0.5" }}
                >
                  <SvgIcon
                    component={LocalLaundryServiceIcon}
                    inheritViewBox
                    sx={{ fontSize: 40 }}
                  />
                </IconButton>

                <IconButton
                  onClick={() => {
                    store.dispatch(setDAPPOpen("proxy"));
                  }}
                  sx={{ opacity: showAppStore ? "1" : "0.5" }}
                >
                  <SvgIcon
                    component={VpnLockIcon}
                    inheritViewBox
                    sx={{ fontSize: 40 }}
                  />
                </IconButton>

                <IconButton
                  onClick={() => {
                    store.dispatch(setDAPPOpen("nodes"));
                  }}
                  sx={{ opacity: showAppStore ? "1" : "0.5" }}
                >
                  <SvgIcon
                    component={HubIcon}
                    inheritViewBox
                    sx={{ fontSize: 40 }}
                  />
                </IconButton>
              </>
            )}

            <Box sx={{ position: "fixed", right: "120px" }}>
              <IconButton
                onClick={(e) => {
                  const kk = e.currentTarget;
                  setAnchorEl(kk);
                }}
              >
                {showLocationIcon(locale)}
              </IconButton>
              <LanguageMenu />
            </Box>

            <IconButton
              onClick={() => {
                if (mode === "light") {
                  return setMode("dark");
                }
                setMode("light");
              }}
              sx={{
                position: "fixed",
                right: "60px",
                opacity: menuValue === 3 ? "1" : "0.5",
              }}
            >
              <SvgIcon
                component={mode === "light" ? DarkIcon : LightIcon}
                inheritViewBox
                sx={{ fontSize: 40 }}
              />
            </IconButton>

            <IconButton
              onClick={() => {
                if (isUnlocked) {
                  setShowProfileDropdown(true);
                }
              }}
              sx={{
                width: "55px",
                position: "fixed",
                right: "0px",
                opacity: menuValue === 0 ? "1" : "0.5",
              }}
            >
              <SvgIcon
                component={animeCONET}
                inheritViewBox
                sx={{ fontSize: 40 }}
              />
            </IconButton>
          </Toolbar>
        </AppBar>
      );
    };

    const languageMenuClick = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      const kk = e.currentTarget;
      setAnchorEl(kk);
    };

    const LanguageMenu = () => {
      return (
        <>
          <Box onClick={languageMenuClick}>Language</Box>
          <Menu
            id="menu-language"
            keepMounted
            open={openMenu}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuOption>aaa</MenuOption>
          </Menu>
        </>
      );

      /* return (
        <Menu
          id="menu-language"
          keepMounted
          open={openMenu}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClick={() => setAnchorEl(null)}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {actions.map(action => (

            locale !== action.name &&
            <MenuItem key={action.name}
              onClick={(e) => {
                setLocale(action.name)
                setAnchorEl(null)
              }}>
              <ListItemIcon>{action.icon}</ListItemIcon>
            </MenuItem>

          ))}
        </Menu>
      ) */
    };

    const ThemeMenuClick = () => {
      if (theme === "Dark") {
        setMode(`light`);
        setTheme("Light");
      } else {
        setMode(`dark`);
        setTheme("Dark");
      }
    };

    return (
      <>
        <Box
          width={isCollapsed ? "96px" : "228px"}
          position="relative"
          id='button-collapse'
          bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"}
          display={{
            xs: !isUnlocked ? 'none' : "none",
            sm: !isUnlocked ? 'none' : "none",
            md: !isUnlocked ? 'none' : "none",
            xm: !isUnlocked ? 'none' : "flex",
            lg: !isUnlocked ? 'none' : "flex",
            xl: !isUnlocked ? 'none' : "flex",
          }}
          height='100svh'
          borderRight={
            mode === "light" ? "1px solid #E4E2E4" : "1px solid #474648"
          }
          flexDirection="column"
          justifyContent="space-between"
          padding="1rem"
          flexShrink={0}
        >
          <Box display="flex" flexDirection="column" gap="2rem">
            {isUnlocked && (
              <Box>{mode === "light" ? <LogoLight /> : <LogoDark />}</Box>
            )}
            {isUnlocked && (
              <>

                {/* <NetworkSwitcher isCollapsed={isCollapsed} /> */}
                <Box
                  style={{
                    height: "28px",
                    width: "28px",
                    background: "white",
                    borderRadius: "50%",
                    position: "absolute",
                    right: "-12px",
                    cursor: "pointer",
                    border: "1px solid #e4e4e4",
                    padding: "1px",
                    marginTop: '50px'
                  }}
                  onClick={() => setCollapsed(!isCollapsed)}
                >
                  <SvgIcon
                    component={isCollapsed ? IconArrowRight : IconArrowLeft}
                    inheritViewBox
                    sx={{ fontSize: 24 }}
                  />
                </Box>

                <MenuOption
                  {...a11yProps(0)}
                  style={{ backgroundColor: menuValue == 0 ? (mode === "light" ? "#EFF2FF" : "#363E59") : 'transparent' }}
                  onClick={(event) => handleChange(event, 0)}
                >
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    style={{ display: 'flex' }}
                  >
                    <SvgIcon
                      component={IconDashboard}
                      inheritViewBox
                      sx={{ fontSize: 24 }}
                    />
                  </motion.div>
                  {!isCollapsed && <FormattedMessage
                    id="sidebar.dashboard"
                    defaultMessage="Dashboard"
                  />}
                </MenuOption>
                {/* <MenuOption {...a11yProps(0)}
                style={{backgroundColor: menuValue == 1 ? (mode === "light" ? "#EFF2FF" : "#363E59") : 'transparent'}}
                  onClick={(event) =>
                    handleChange(event, 1)
                  }
                >
                  <SvgIcon
                    component={IconApps}
                    inheritViewBox
                    sx={{ fontSize: 24 }}
                  />
                  {!isCollapsed && <FormattedMessage
                    id="sidebar.daemon"
                    defaultMessage="Daemon"
                  />}
                </MenuOption> */}
                {/* <MenuOption

                  {...a11yProps(2)}
                  style={{ backgroundColor: menuValue == 2 ? (mode === "light" ? "#EFF2FF" : "#363E59") : 'transparent' }}
                // onClick={(event) =>
                //   handleChange(event, 2)
                // }
                >
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    style={{ display: 'flex' }}
                  >
                    <SvgIcon
                      component={IconExplorer}
                      inheritViewBox
                      sx={{ fontSize: 24 }}
                    />
                  </motion.div>
                  {!isCollapsed && <FormattedMessage
                    id="sidebar.explorer"
                    defaultMessage="Explorer"
                  />}
                </MenuOption> */}
                <MenuOption {...a11yProps(0)}
                  style={{ backgroundColor: menuValue == 1 ? (mode === "light" ? "#EFF2FF" : "#363E59") : 'transparent' }}
                  onClick={(event) =>
                    handleChange(event, 1)
                  }
                >
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    style={{ display: 'flex' }}
                  >
                    <SvgIcon
                      component={IconLeaderboard}
                      inheritViewBox
                      sx={{ fontSize: 24 }}
                    />
                  </motion.div>
                  {!isCollapsed && <FormattedMessage
                    id="sidebar.leaderboard"
                    defaultMessage="Leaderboard"
                  />}
                </MenuOption>
                {/* <MenuOption {...a11yProps(0)}
                  style={{ backgroundColor: menuValue == 1 ? (mode === "light" ? "#EFF2FF" : "#363E59") : 'transparent' }}
                  onClick={(event) =>
                    handleChange(event, 5)
                  }
                >
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    style={{ display: 'flex' }}
                  >
                    <SvgIcon
                      component={IconExplorer}
                      inheritViewBox
                      sx={{ fontSize: 24 }}
                    />
                  </motion.div>
                  {!isCollapsed && <FormattedMessage
                    id="sidebar.bridge"
                    defaultMessage="Web2 Bridge"
                  />}
                </MenuOption> */}
              </>
            )}
          </Box>

          <Box display="flex" flexDirection="column" gap="1rem">
            {isUnlocked && (
              <>
                <MenuOption
                  {...a11yProps(3)}
                  onClick={(event) => handleChange(event, 4)}
                >
                  <SvgIcon
                    component={IconApps}
                    inheritViewBox
                    sx={{ fontSize: 24 }}
                  />
                  {!isCollapsed && <FormattedMessage id="sidebar.apps" defaultMessage="Apps" />}
                </MenuOption>
                <Box display="flex" gap={isCollapsed ? "12px" : "8px"} alignItems="center" flexDirection={isCollapsed ? "column" : 'row'}>
                  <ThemeOption
                    onClick={ThemeMenuClick}
                    bgcolor={mode === "light" ? "#79F8FF" : "#D775FF"}
                    marginRight={isCollapsed ? '8px' : '0px'}
                  >
                    <SvgIcon
                      component={mode === "light" ? LightIcon : DarkIcon}
                      inheritViewBox
                      sx={{ fontSize: 20 }}
                    />
                  </ThemeOption>
                  {LanguageSwitcher(isCollapsed)}
                </Box>
                <Box
                  borderTop={
                    mode === "light" ? "1px solid #E4E2E4" : "1px solid #474648"
                  }
                />
                <Box
                  onClick={(event) => handleChange(event, 3)}
                  display="flex"
                  gap="0.5rem"
                  alignItems="center"
                  bgcolor={mode === "light" ? "#EFF2FF" : "#363E59"}
                  /* onClick account */
                  p="1rem"
                  borderRadius="1rem"
                  style={{ cursor: 'pointer' }}
                >{
                    (userProfile && userProfile.data) && userProfile?.data?.profileImg ?
                      (<img src={` ${userProfile?.data?.profileImg}`} alt="Imagem Codificada em Base64" height='20px' width='20px' style={{ borderRadius: '100%' }} />) :
                      <SvgIcon
                        component={ConetAvatar}
                        inheritViewBox
                        sx={{ fontSize: 32 }}
                      />
                  }

                  {!isCollapsed && <Typography fontWeight={500} fontSize="12px">
                    {userProfile?.data?.nickname || intl.formatMessage({ id: 'platform.ProfileDropdown.CurrentProfileItem.AnonymousUser' })}
                  </Typography>}
                </Box>
              </>
            )}
          </Box>
        </Box>
        <MobileSidebar handleChange={handleChange} profile={userProfile} currentProfile={currentProfile} />
      </>
    );

    /*
    return (
      <>
        <StyledTabs
          value={menuValue}
          onChange={handleChange}
          sx={{
            backgroundColor: mode === 'light' ? '#ffffff' : '#111113',
            width: '180px',
            display: { xs: 'none', sm: 'flex', md: 'flex', xm: 'flex', lg: 'flex', xl: 'flex' },
            padding: '1rem',
            paddingTop: '2rem',
            borderRight: mode === 'light' ? '1px solid #E4E2E4' : '1px solid #474648',
          }}
        >

                <Box>
                    {mode === 'light' ? <LogoLight /> : <LogoDark />}
                </Box>

                {
                    isUnlocked &&
                    <>
                        <Box display="flex"
                            alignItems="center"
                            bgcolor="#F6F8FF"
                            borderRadius="50px"
                            border="1px solid #577DFF"
                            p="0.25rem 1rem"
                            marginTop="2rem"
                            lineHeight="16px"
                            gap="8px"
                            fontSize="14px"
                        >
                            Main network
                            <SvgIcon component={ExpandMoreIcon} inheritViewBox sx={{ fontSize: 16 }} />
                        </Box>
                        <MenuOption {...a11yProps(0)}>
                            <SvgIcon component={IconDashboard} inheritViewBox sx={{ fontSize: 24 }} />
                            <FormattedMessage id="sidebar.dashboard" defaultMessage="Dashboard" />
                        </MenuOption>
                        <MenuOption {...a11yProps(2)}>
                            <SvgIcon component={IconExplorer} inheritViewBox sx={{ fontSize: 24 }} />
                            <FormattedMessage id="sidebar.explorer" defaultMessage="Explorer" />
                        </MenuOption>
                    </>
                }

                <Box {...a11yProps(2)} onClick={languageMenuClick}>
                    <Tab icon={showLocationIcon(locale)} sx={{ position: 'fixed', bottom: '10rem'}} />
                    <LanguageMenu />
                </Box>

                <Box {...a11yProps(3)} bgcolor="red" onClick={
                    e => {
                    if (theme === 'Light') {
                        setMode(`dark`)
                        setTheme('Dark')
                    } else {
                        setMode(`light`)
                        setTheme('Light')
                    }
                    }
                }>
                    <StyledTab icon={<SvgIcon component={mode === 'light' ? DarkIcon : LightIcon} inheritViewBox sx={{ fontSize: 40 }} />} sx={{ position: 'fixed', bottom: '5.5rem' }} />
                </Box>

          <Box {...a11yProps(4)} onClick={
            e => {
              if (isUnlocked) {
                setShowProfileDropdown(true)
              }

            }
          }>

            <Tab icon={<SvgIcon component={animeCONET} inheritViewBox />} sx={{ position: 'fixed', bottom: '0px' }} />

          </Box>
        </StyledTabs>
        <StyledTabsMobile />
        <Dialog
          open={showProfileDropdown}
          onClose={() => setShowProfileDropdown(false)}
          maxWidth='xs'
        >
          <ProfileDropdown
            closeDropdown={() => {
              setShowProfileDropdown(false)
            }}
          />
        </Dialog>
      </>

    );

    */
  };

  const AppStart = () => {
    const [step, setStep] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <StackContainer /* sx={{ height: isMobile ? '91vh' : '100vh' }} */>
        <Container
          // maxWidth="lg"
          sx={{
            height: "100%",
            width: "100%",
            overflowX: "auto",
            padding: '0!important',
            paddingTop: '40px',
            margin: '0 auto',
            maxWidth: '100%!important'
          }}
        >
          {!isInitializing && (
            <Stack
              sx={{ width: "100%", height: "100%" }} alignItems="center"
            >
              {/* <ShowApp /> */}

              {hasContainer && isUnlocked ? (
                <>
                  <ShowApp />
                  <FooterPlatform />
                </>
              ) : (
                <WelcomeToConet onClickStep={(e) => setStep(e)} />
              )}
            </Stack>
          )}
          {isInitializing && (
            <Stack
              sx={{
                width: "100%",
                height: "100vh",
                padding: "2rem",
                margin: "-3rem 0 0 0",
              }}
              alignItems="center"
              justifyContent="center"
            >
              <LogoImage color="grey" />
            </Stack>
          )}
        </Container>
      </StackContainer>
    );
  };

  return (
    <StackContainer
      direction={{ xs: "column", sm: "row" }}
      sx={{ minHeight: "25rem" }}
    >
      <MenuSideBar />
      <AppStart />
    </StackContainer>
  );
};

export default DashBoard;
