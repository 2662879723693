import { Box, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect } from "react";
import '../styles/style.css';

interface IPlanCard {
  image: string;
  plan: string;
  title: string;
  isSelectedPlan: string;
  setIsSelectedPlan: React.Dispatch<React.SetStateAction<string>>;
}

export default function PlanCard({
  image,
  title,
  isSelectedPlan,
  setIsSelectedPlan,
  plan,
}: IPlanCard) {

  useEffect(() => {
    const handleMouseMove = (e) => {
      const rect = e.target.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      const xPercent = (x / rect.width) * 100;
      const yPercent = (y / rect.height) * 100;

      e.target.style.setProperty("--x", xPercent + "%");
      e.target.style.setProperty("--y", yPercent + "%");
    };

    const cards = document.querySelectorAll(".card");
    cards.forEach((card) => {
      card.addEventListener("mousemove", handleMouseMove);

      return () => {
        card.removeEventListener("mousemove", handleMouseMove);
      };
    });
  }, []);

  return (
    <motion.div whileTap={{ scale: 1.0125 }} style={{ width: "100%", padding: '1px', }} className="cardAbove">
      <Box
        className='card'
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        onClick={() => setIsSelectedPlan(plan)}
        borderRadius={"8px"}
        p={"28px"}
        width={'100%'}
        height={['85px', "120px"]}
        sx={{
          zIndex: '2',
          width: '100%',
          border: isSelectedPlan === plan ? '0.938px solid #8D4BE1' : '0.938px solid #b1b1b2',
          cursor: 'pointer',
          background: localStorage.getItem('mui-mode') === 'light' ? "white" : "#1B1B1D",
        }}
      >
        <Stack
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          width={[20, 30, 40]}
        >
          <img src={image} style={{ width: '100%', marginBottom: '4px' }} />
          <Typography textAlign={"center"} color={isSelectedPlan === plan ? localStorage.getItem('mui-mode') === 'light' ? '#0d0d0d' : '#fff' : '#b1b1b2'} fontSize={[14, 18]}>{title}</Typography>
        </Stack>
      </Box>
    </motion.div>
  );
}
